import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import LoadSpinner from '../assets/svgs/loadSpinner';
import fetchWithCSRF from '../lib/fetchWithCSRF';

function Login({ setLoggedIn, setVerified, setAdmin }) {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [searchParams] = useSearchParams();
  const redirectValue = searchParams.get('redirect');

  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState('');

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let password = formData.password;
    let email = formData.email;

    let validation = {};

    const data = {
      email: email,
      password: password,
    };

    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        validation[key] = 'Required';
      }
    });

    if (Object.keys(validation)[0]) {
      setValidationErrors(validation);
      return;
    }

    setError('');
    setIsSubmitting(true);

    fetchWithCSRF('/login', data, 'POST')
      .then(async (login) => {
        const result = await login.json();
        if (result.success) {
          const { auth, verified, role } = result;
          console.log(auth, verified, role);
          if (auth) {
            setLoggedIn(true);
            sessionStorage.setItem('auth', true);

            console.log(role);

            if (role === 'admin') {
              setAdmin(true);
            } else {
              setAdmin(false);
            }

            if (verified) {
              setVerified(true);
              sessionStorage.setItem('email_verified', true);
              if (redirectValue) {
                navigate(`/${redirectValue}`);
              } else {
                navigate('/home');
              }
            } else {
              navigate(
                `/please-verify?${
                  redirectValue && '&redirect=' + redirectValue
                }`
              );
            }
          }
        } else {
          if (result.error) {
            setError(result.error);
          } else {
            setError('Server Error');
          }
          setLoggedIn(false);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="py-10 w-full min-h-[73vh] flex items-center m-auto text-left bg-white">
      <div className="m-auto w-full">
        <h1 className="text-theblue text-center mb-3">My Account</h1>
        <form className="bg-white max-[768px]:max-w-[90%] max-[1024px]:px-0 max-w-lg m-auto px-8 pt-6 pb-8 mb-4">
          <div className="mb-4 text-left">
            <label
              className="block text-theblue text-inherit text-sm mb-2"
              htmlFor="email"
            >
              Email Address
            </label>

            <input
              className="appearance-none border w-full py-2 px-3 text-theblue leading-tight focus:outline-[#014165]  focus:shadow-outline"
              id="email"
              type="text"
              name="username"
              autoComplete="off"
              value={formData.email}
              onChange={handleInputChange}
            />
            {validationErrors.email && (
              <p className="text-red-600 sans  text-sm mt-2">
                Required
              </p>
            )}
          </div>

          <div className="mb-6">
            <label
              className="text-left block text-theblue text-sm mb-2"
              htmlFor="password"
            >
              Password
            </label>

            <input
              className="appearance-none border w-full py-2 px-3 text-theblue  leading-tight focus:outline-[#014165]  focus:shadow-outline"
              id="password"
              type="password"
              name="password"
              autoComplete="off"
              value={formData.password}
              onChange={handleInputChange}
            />

            {validationErrors.password && (
              <p className="text-red-600 sans  text-sm mt-2">
                Required
              </p>
            )}
            <div className="w-full flex justify-end mt-2">
              <a
                className="!text-theblue text-sm underline cursor-pointer sans"
                href="/forgot-password"
              >
                Forgot password
              </a>
            </div>
          </div>

          <div className="grid">
            <button
              className="bg-theblue border-0 text-white py-2 px-4"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? <LoadSpinner /> : 'Sign In'}
            </button>
            <div className={`mt-2 h-auto`}>
              <p
                className={`text-red-600 sans  text-sm text-center mt-2 h-auto p-2 ${
                  error && 'server-error'
                }`}
              >
                {error}
              </p>
            </div>

            <p className="p-3 m-auto text-theblue sans">
              No account?&nbsp;
              {redirectValue ? (
                <a
                  className="inline-block align-baseline text-sm text-theblue"
                  href={`/register?redirect=${redirectValue}`}
                >
                  Create account
                </a>
              ) : (
                <a
                  className="inline-block align-baseline text-sm text-theblue"
                  href="/register"
                >
                  Create account
                </a>
              )}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
