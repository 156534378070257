import React from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

function Terms({ setLoggedIn, loggedIn, isAdmin }) {
  return (
    <div className="home font-playfair bg-theblue text-white p-0 m-0">
      <Navbar
        color="white"
        setLoggedIn={setLoggedIn}
        loggedIn={loggedIn}
        isAdmin={isAdmin}
      />
      <div className="bg-white">
        <h1 className="text-theblue">Terms of Use</h1>
        <div className="sans text-black my-12 max-w-[700px] mx-auto text-left">
          <strong className="text-sm">
            Last updated: September 2024
          </strong>
          <br />
          <br />
          <h2 className="uppercase text-lg">
            About Experience Time and these Terms of Use
          </h2>
          <p>
            These terms of use (<strong>“Terms of Use”</strong>)
            govern your use of the Experience Time website (the
            <strong>“Platform”</strong>). Please read these Terms of
            Use carefully before using the Platform. By using the
            Platform, you signify your assent and agreement to these
            Terms of Use. If you do not agree to these Terms of Use,
            then you are not authorised to continue use of the
            Platform.
          </p>
          <br />
          <h3 className="text-[16px] font-bold">
            Updates to these Terms of Use
          </h3>
          <p>
            We may make changes from time to time to these Terms of
            Use so please check back regularly to keep informed of
            updates. The latest version of these Terms of Use will
            always be available on the Platform. Any new version of
            these Terms of Use shall take effect and will govern the
            use of the Platform and your relationship with us
            immediately upon the date of posting. By continuing to use
            the Platform, you agree to be bound by the terms of these
            updates and amendments.
          </p>
          <br />
          <br />
          <h2 className="uppercase text-lg">
            About our hosting services
          </h2>
          <p>
            Hosting services for our website is provided by the
            Provider in the US.
          </p>
          <br />
          <br />
          <h2 className="uppercase text-lg">Our Privacy Policy</h2>
          <p>
            Our information collection practices on the Platform, such
            as the types of information we collect regarding visitors
            to the Platform and the ways in which we may use that
            information, are governed by the terms of our{' '}
            <a href="/privacy-policy">Privacy Policy</a>.
          </p>
          <br />
          <br />
          <h2 className="uppercase text-lg">
            Use of materials on the Platform
          </h2>
          <p>
            We have created the Platform for your personal use to
            provide you information about Experience Time and its
            services. Unless otherwise stated, you should assume that
            everything that you see or read on the Platform (such as
            creations, products, images, photographs, including any
            person represented in the photographs, illustrations,
            icons, texts, video clips, music, written and other
            materials) (<strong>“Experience Time Material”</strong>)
            are protected by legislation such as copyright, designs
            and trademark legislation and under international treaty
            provisions and national laws worldwide.
          </p>
          <p>
            You are not authorised to sell, reproduce, distribute,
            communicate, modify, display, publicly perform, report or
            otherwise prepare derivative or second hand works based on
            or use any Experience Time Material in any way for any
            public or commercial purposes. Furthermore, Experience
            Time Material may not be displayed or communicated on any
            other platform, in a networked computer environment or on
            any other digital platform for any purpose whatsoever. In
            the event of breach of any of these Terms of Use, your
            permission to use Experience Time Material will
            automatically terminate and any copies made of Experience
            Time Material must be immediately destroyed. Any
            unauthorised use of Experience Time Material may infringe
            copyright laws, trademark laws, the laws of privacy and
            publicity, and communications regulations and statutes.
          </p>
          <br />
          <br />
          <h2 className="uppercase text-lg">
            Your submissions and unsolicited communications
          </h2>
          <p>
            This section concerns communications sent to Experience
            Time. It does not concern the communication of personal
            information to Experience Time in relation to enquiries;
            such information is governed by the rules stipulated in
            the <a href="/privacy-policy">Privacy Policy</a>.
          </p>
          <p>
            Any unsolicited communication or material that you
            transmit to Experience Time via the Platform or through
            social media, by electronic mail or otherwise, including,
            but not limited to, any data, questions or answers,
            comments, suggestions, or the like will be treated by us
            as non-confidential and non-proprietary.
          </p>
          <br />
          <br />
          <h2 className="uppercase text-lg">
            Limitation of liability
          </h2>
          <p>
            This section applies to the Platform and not to the
            services of Experience Time.
          </p>
          <p>
            Experience Time tries to ensure that the information
            provided is accurate and complete. However, Experience
            Time does not warrant or represent that Experience Time
            Material is accurate, error-free or reliable or that use
            of Experience Time Material will not infringe rights of
            third parties.
          </p>
          <p>
            We do not warrant that the functional and/or technical
            aspects of the Platform or the Experience Time Material
            will be error free or that the Platform, Experience Time
            Material or the servers that make them available are free
            of viruses or other harmful components. If use of the
            Platform or Experience Time Material results in the need
            for servicing or replacing property, material, equipment,
            data or other element, Experience Time is not responsible
            for those costs. Without limiting the foregoing,
            everything on the Platform is provided to you "AS IS" AND
            "AS AVAILABLE" AND, TO THE FULLEST EXTENT PERMITTED BY
            APPLICABLE LAW, WITHOUT WARRANTY OF ANY KIND, EITHER
            EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
            IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY
            QUALITY, FITNESS FOR A PARTICULAR PURPOSE, REASONABLE CARE
            AND SKILL, OR NON-INFRINGEMENT. Experience Time makes no
            warranties about the Experience Time Material, software,
            text, downloads, graphics, and links, or about results to
            be obtained from using the Platform.
          </p>
          <p>
            To the fullest extent permitted by applicable law,
            Experience Time shall not be liable for any indirect,
            incidental, special or consequential damages of any kind
            arising out of or in connection with the use of
            information available from the Platform or any liability
            relating to any loss of use, interruption of business,
            lost profits or lost data, regardless of the form of
            action, whether in contract, tort (including negligence)
            or otherwise, even if Experience Time has been advised of
            the possibility of such damages.{' '}
          </p>
          <p>
            Please note that in some jurisdictions consumer protection
            laws may not allow certain exclusions or limitation of
            warranties or liabilities, and consequently some of the
            above exclusions and limitations may not apply.
          </p>
          <br />
          <br />
          <h2 className="uppercase text-lg">Trademark notice</h2>
          <p>
            In general, all trademarks, logos and service marks
            (collectively the <strong>“Trademarks”</strong>) that
            appear on the Platform are registered, unregistered or
            otherwise protected Experience Time trademarks or are
            licensed for use by Experience Time by third parties.
            Other trademarks are proprietary marks and are registered
            to their respective owners. Nothing contained on the
            Platform should be construed as granting, by implication
            or otherwise, any licence or right to use any trademark
            without Experience Time’s prior written permission or that
            of such third party who owns the trademark. Misuse of any
            trademark displayed on the Platform, or any other content
            on the Platform, except as provided herein, is strictly
            prohibited.
          </p>
          <br />
          <br />
          <h2 className="uppercase text-lg">Copyright notice</h2>
          <p>
            All content (including Experience Time Materials) on the
            Platform are either Copyright © Experience Time or are
            licensed for use by Experience Time. All rights are
            reserved. Please refer to the section above on Use of
            Materials on the Platform.
          </p>
          <br />
          <br />
          <h2 className="uppercase text-lg">
            Termination and suspension
          </h2>
          <p>
            You agree that Experience Time may terminate or suspend
            your access to and use of the Platform if it reasonably
            believes that you have violated or acted inconsistently
            with the letter or spirit of these Terms of Use, or
            violated the rights of Experience Time, its affiliated
            companies or any third party, with or without notice to
            you. You agree that Experience Time may modify or
            discontinue your access to the Platform, with or without
            notice to you. You agree that Experience Time will not be
            liable to you or any third party as a result of such
            modification or discontinuation. The provisions entitled
            "Limitation of liability" and "General provisions" will
            survive termination of these Terms of Use.
          </p>
          <br />
          <br />
          <h2 className="uppercase text-lg">General provisions</h2>
          <p>
            Unless otherwise specified, the information and Experience
            Time Materials presented on the Platform are presented
            solely for the purpose of promoting Experiencing Time’s
            services.
          </p>
          <p>
            If any provision, or part of a provision, of these Terms
            of Use is found to be illegal, invalid or unenforceable,
            that provision or part-provision shall be deemed not to
            form part of these Terms of Use, and the legality,
            validity or enforceability of the remainder of the
            provisions of these Terms of Use shall not be affected,
            unless otherwise required by operation of applicable law.
          </p>
          <p>
            These Terms of Use constitute the entire agreement between
            you and us in relation to the use of the Platform, and
            replace and extinguish all prior agreements, draft
            agreements, arrangements, undertakings, or collateral
            contracts of any nature made by the parties, whether oral
            or written, in relation to such subject matter.
          </p>
          <p>
            The waiver by Experience Time of a breach of any provision
            of these Terms of Use will not operate to be interpreted
            as a waiver of any other or subsequent breach.{' '}
          </p>
          <br />
          <br />
          <h2 className="uppercase text-lg">
            Applicable law and jurisdiction
          </h2>
          <p>
            These Terms of Use shall be governed by and construed in
            accordance with the laws of the State of New York, without
            reference to conflict of laws provisions. Any dispute,
            controversy or claim arising out of or in relation to the
            Terms of Use, including the validity, invalidity, breach
            or termination thereof, shall be adjudicated or arbitrated
            in accordance with said Terms of Use.{' '}
          </p>
          <br />
          <br />
          <h2 className="uppercase text-lg">Contact us</h2>
          <p>
            If you have any questions or comments about these Terms of
            Use, or matters generally, please contact us at the
            address provided below.
          </p>
          <br />
          <a
            href="mailto: 
ExperienceTimeAmericas@richemont.com"
            className="text-inherit"
          >
            ExperienceTimeAmericas@richemont.com
          </a>
        </div>
      </div>
      <Footer color="blue" />
    </div>
  );
}

export default Terms;
