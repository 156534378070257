import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/experiencelogo.png';
import whitelogo from '../assets/images/experiencelogo-w.png';

function Footer({ showLinks = true, ...props }) {
  let nameofclass;
  let thelogo;

  if (props.color === 'white') {
    nameofclass =
      'font-playfair mt-20 sticky top-[100vh] py-20 m-auto text-theblue bg-white flex flex-nowrap justify-around items-center  max-sm:grid max-sm:align-content: center';
    thelogo = logo;
  } else if (props.color === 'blue') {
    nameofclass =
      'font-playfair mt-20 sticky top-[100vh] py-20 m-auto text-white bg-theblue flex flex-nowrap justify-around items-center max-sm:grid max-sm:align-content: center';
    thelogo = whitelogo;
  }

  return (
    <div className={nameofclass}>
      {showLinks ? (
        <>
          <div className="text-inherit mx-auto max-sm:my-3">
            <a
              href="mailto: 
ExperienceTimeAmericas@richemont.com"
              className="text-inherit no-underline"
            >
              Contact Us
            </a>
          </div>
        </>
      ) : (
        <span className="text-inherit m-auto"></span>
      )}

      <div
        id="footerimg"
        className="w-1/3 max-sm:w-2/3 max-sm:m-auto max-sm:my-3 max-sm:order-first"
      >
        <Nav.Link
          className="text-inherit m-auto"
          as={NavLink}
          to={showLinks ? "/" : null}
        >
          <img
            src={thelogo}
            className="w-full max-w-[300px]"
            alt="Experience Time Logo"
          />
        </Nav.Link>
      </div>
      {showLinks ? (
        <div className="mx-auto">
          <Nav.Link
            to="/privacy-policy"
            as={NavLink}
            className="text-inherit mx-auto  max-sm:my-3"
          >
            Privacy Policy
          </Nav.Link>
          <Nav.Link
            to="/terms"
            as={NavLink}
            className="text-inherit mx-auto  max-sm:my-3"
          >
            Terms of Use
          </Nav.Link>
          <Nav.Link
            to="/promotion-terms"
            as={NavLink}
            className="text-inherit mx-auto  max-sm:my-3"
          >
            Promotion Terms
          </Nav.Link>
        </div>
      ) : (
        <span className="text-inherit m-auto"></span>
      )}
    </div>
  );
}
export default Footer;
