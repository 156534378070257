// UPDATE 3 - REUSABLE FETCH USER FUNCTION (DRY-ER CODE)

export default async function fetchUser() {
  try {
    const response = await fetch('/user', {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`HTTP Error: Status ${response.status}`);
    }

    const data = await response.json();
    return { data, error: null };
  } catch (err) {
    return { data: null, error: err.message || 'Server Error' };
  }
}
