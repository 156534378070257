import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import FadeUpSection from './fadeUpSection';
import 'bootstrap/dist/css/bootstrap.min.css';

function PassportInfo() {
  return (
    <div className="font-playfair bg-[#fffffff7] text-white p-0">
      <FadeUpSection classes="p-16 md:p-28 flex-col space-y-10">
        <h2 className="text-theblue font-playfair mb-8 text-3xl">
          About Experience Time
        </h2>
        <p className="w-full lg:w-[75%] m-auto max-w-[750px] sans text-theblue">
          This event, brought from Geneva to Los Angeles, is making
          its mark for the first time in the Americas to bring you the
          excellence of luxury watchmaking, craftsmanship, and the
          heritage of each Maison. Enjoy meeting watchmaking artisans,
          a variety of live experiences & surprises, and immerse
          yourself into the world of timepieces.
        </p>
      </FadeUpSection>
    </div>
  );
}
export default PassportInfo;
