// AdminExportButton.js

import React from 'react';

import { CSVLink } from 'react-csv';
import fetchData from '../lib/fetchData';
import fetchUser from '../lib/fetchUser';

class AdminExportButton extends React.Component {
  state = {
    data: [],
    watchTimeData: [],
    isAdmin: false,
  };

  handleExport = async () => {
    try {
      const { data, error } = await fetchUser();
      if (error) {
        alert('An error occurred while processing your request.');
      } else if (data.role === 'admin') {
        this.setState({ isAdmin: true });

        const allDataResponse = await fetchData('/api/clients');

        this.setState({ data: allDataResponse.data });
      } else {
        alert('You do not have permission to export the database.');
      }
    } catch (error) {
      alert('An error occurred while processing your request.');
    }
  };

  handleWatchTimeExport = async () => {
    try {
      const { data, error } = await fetchUser();
      if (error) {
        alert('An error occurred while processing your request.');
      }

      if (data.role === 'admin') {
        this.setState({ isAdmin: true });

        const allDataResponse = await fetchData(
          '/api/watchtimeclients'
        );

        this.setState({ watchTimeData: allDataResponse.data });
      } else {
        alert('You do not have permission to export the database.');
      }
    } catch (error) {
      //console.error("Error checking admin role or fetching data:", error);

      alert('An error occurred while processing your request.');
    }
  };
  render() {
    const { data, watchTimeData, isAdmin } = this.state;

    return (
      <div className="flex items-center justify-center gap-4 h-[300px] bg-white">
        <div className="flex flex-col gap-2">
          <button
            onClick={this.handleExport}
            className="bg-white text-theblue p-2 hover:bg-theblue sans"
          >
            Export Experience Time Database
          </button>

          {isAdmin && data.length > 0 ? (
            <CSVLink data={data} filename={'swm_database_export.csv'}>
              Download Experience Time CSV
            </CSVLink>
          ) : (
            <span className="h-4"></span>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <button
            onClick={this.handleWatchTimeExport}
            className="bg-white text-theblue p-2 hover:bg-theblue sans"
          >
            Export Watch Time Database
          </button>
          {isAdmin && watchTimeData.length > 0 ? (
            <CSVLink
              data={watchTimeData}
              filename={'watch_time_database_export.csv'}
            >
              Download Watch Time CSV
            </CSVLink>
          ) : (
            <span className="h-4"></span>
          )}
        </div>
      </div>
    );
  }
}

export default AdminExportButton;
