import { useState } from 'react';
import LoadSpinner from '../assets/svgs/loadSpinner';
import fetchUser from '../lib/fetchUser';
import useEffectOnce from '../hooks/useEffectOnce';

const PassportModal = (props) => {
  const [passportId, setPassport] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  async function getPassportId() {
    try {
      const { data, error } = await fetchUser();
      if (data) {
        const digitalPassport = data.digital_passport_id;
        setPassport(digitalPassport);
      } else {
        setError(error || 'Something went wrong, please try again');
      }
    } catch (err) {
      setError(
        err.message || 'Something went wrong, please try again'
      );
    } finally {
      setLoading(false);
    }
  }

  useEffectOnce(() => {
    getPassportId();
  }, []);

  // if (loading) {
  //   return (
  //     <div className="w-full h-full m-auto inset-0 fixed p-0 z-50 text-theblue bg-white">
  //       <div className="w-full flex items-center justify-center h-full m-0 p-0">
  //         <LoadSpinner size="w-20 h-20" />
  //       </div>{' '}
  //     </div>
  //   );
  // }
  return (
    <>
      <div className="w-full h-full m-auto inset-0 fixed p-0 z-50 text-theblue bg-white">
        <div className="w-full h-full m-0 p-0 z-10 relative">
          <div
            className="close text-right h-12 w-full border-0 bg-white flex items-center justify-end"
            onClick={props.onClose}
          >
            <button className="bg-white border-none mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#014165"
                  d="M256 21.333A234.667 234.667 0 1 0 490.666 256 234.94 234.94 0 0 0 256 21.333Zm105.578 310.102a21.332 21.332 0 0 1-23.24 34.946 21.325 21.325 0 0 1-6.925-4.781L256 286.165 180.565 361.6a21.335 21.335 0 0 1-29.905-.26 21.332 21.332 0 0 1-.26-29.905L225.834 256 150.4 180.565a21.332 21.332 0 0 1 23.24-34.946 21.325 21.325 0 0 1 6.925 4.781L256 225.835l75.413-75.435a21.325 21.325 0 0 1 15.16-6.512 21.32 21.32 0 0 1 15.27 6.248 21.34 21.34 0 0 1-.265 30.429L286.165 256l75.413 75.435Z"
                />
              </svg>
            </button>
          </div>

          {error ? (
            <p>{error}</p>
          ) : (
            <>
              <div className="w-full h-full m-auto inset-0 p-0 z-50 text-theblue bg-white absolute top-[90px] left-0 z-1">
                <div className="w-full flex items-center justify-center h-full m-0 p-0">
                  <LoadSpinner size="w-12 h-12" />
                </div>
              </div>
              {!loading && (
                <iframe
                  className="p-0 m-0 z-10 relative"
                  style={{ height: '95%', width: '100%' }}
                  src={`https://passport.inspify.io/rodeoDrive2024?ref=${passportId}`}
                  title="Digital Passport"
                  allow="web-share"
                ></iframe>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default PassportModal;
