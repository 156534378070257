import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LoadSpinner from '../assets/svgs/loadSpinner';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import fetchWithCSRF from '../lib/fetchWithCSRF';

const ResetPassword = ({ setLoggedIn, setVerified }) => {
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');
  const token = searchParams.get('token');

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const verifyUser = async () => {
    let success;
    try {
      const result = await fetchWithCSRF(
        '/api/verify-user',
        {
          id: id,
          token: token,
        },
        'POST'
      );

      const response = await result.json();

      // If verification was successful
      if (response.success) {
        success = true;
      } else if (response.error) {
        setFormError(response.error);
        setFailed(true);
        setFormLoading(false);
      }
    } catch (err) {
      setFormError(err.response?.data || 'Server Error');
      setFormLoading(false);
      setFailed(true);
      success = false;
    }
    return success;
  };

  const testPassword = (pw) => {
    let regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$ %^&*-])/;

    if (pw.length < 10 || !regex.test(pw)) {
      return false;
    }

    return true;
  };

  const resetPassword = async (newPassword, confirmPassword, e) => {
    e.preventDefault();
    setValidationErrors({});
    setFormError('');
    setFormLoading(true);

    let errors = {};

    if (!newPassword || !confirmPassword) {
      if (!newPassword) {
        errors.newPassword = 'Required';
      }
      if (!confirmPassword) {
        errors.confirmPassword = 'Required';
      }
      setValidationErrors(errors);
      setFormLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      setFormError('Passwords must match');
      setFormLoading(false);
      return;
    }

    if (!testPassword(newPassword)) {
      setFormError(
        'Password must contain at least one number, one uppercase and lowercase letter, one symbol, and be at least 10 characters long'
      );
      setFormLoading(false);
      return;
    }

    const userAuthenticated = await verifyUser();

    if (!userAuthenticated) return;

    try {
      const result = await fetchWithCSRF(
        '/api/reset-password',
        {
          password: newPassword,
          confirmPassword: confirmPassword,
        },
        'POST'
      );
      const response = await result.json();
      if (response.success) {
        setSuccess(true);
        setConfirmPassword('');
        setNewPassword('');
      } else {
        if (response.error) {
          setFormError(response.error);
        } else {
          setFormError('Server Error');
        }
        setFailed(true);
      }
    } catch (err) {
      setFormError('Server Error');
      setFailed(true);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <div className="login font-playfair bg-theblue text-white p-0 m-0">
      <Navbar color="white" setLoggedIn={setLoggedIn} />
      <div className="py-10 w-full min-h-[73vh]  m-auto text-left bg-white">
        <h1 className="text-theblue text-center mb-3 text-2xl">
          Reset Password
        </h1>
        <form className="flex flex-col max-w-[500px] mx-auto mt-8 mb-16">
          <label
            htmlFor="newPassword"
            className="block text-theblue text-inherit text-sm mb-2"
          >
            New Password
          </label>
          <input
            className="appearance-none border w-full py-2 px-3 text-theblue leading-tight focus:outline-[#014165]  focus:shadow-outline"
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          ></input>
          <p className="text-red-600 sans  text-sm mt-2">
            {validationErrors.newPassword}
          </p>

          <label
            htmlFor="confirmPassword"
            className="block text-theblue text-inherit text-sm mb-2 mt-4"
          >
            Confirm New Password
          </label>
          <input
            className="appearance-none border w-full py-2 px-3 text-theblue leading-tight focus:outline-[#014165]  focus:shadow-outline"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></input>
          <p className="text-red-600 sans  text-sm mt-2">
            {validationErrors.confirmPassword}
          </p>

          <button
            className="bg-theblue border-0 text-white py-2 px-4 mt-4 disabled:opacity-60"
            disabled={formLoading || success}
            onClick={(e) =>
              resetPassword(
                newPassword.trim(),
                confirmPassword.trim(),
                e
              )
            }
          >
            {formLoading ? <LoadSpinner /> : 'Reset my password'}
          </button>
          {formError && (
            <div
              className={`mt-2 min-h-8 h-auto p-2 ${
                formError && 'bg-[#ef444416]'
              }`}
            >
              <p className="text-red-600 sans  text-sm text-center mt-1 mb-0 break-words">
                {formError}
              </p>
            </div>
          )}

          {failed && (
            <div className="mt-4 text-center">
              <a
                className="text-theblue text-sm mx-auto underline cursor-pointer"
                href="/forgot-password"
              >
                Send me another code
              </a>
            </div>
          )}
          {success && (
            <div>
              <div className="mt-2 min-h-8 h-auto p-2 bg-blue-50">
                <p className="text-blue-900 text-sm text-center mt-2 mb-1 sans">
                  Your password has been successfully reset!
                </p>
              </div>
              <div className="mt-4 text-center">
                <a
                  className="text-theblue text-sm mx-auto underline cursor-pointer"
                  href="/login"
                >
                  Log In
                </a>
              </div>
            </div>
          )}
        </form>
      </div>
      <Footer color="blue" />
    </div>
  );
};

export default ResetPassword;
