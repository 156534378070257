
const IWCModal = (props) => {
  if (!props.show) {
    return null;
  }
  const handleClick = (id) => () =>  {
        props.onDataChange(id);

    props.onOpen();
  };
  return (
    <div className="w-full h-full fixed bg-[#000000b0] z-10">
      <div className="w-1/2 max-md:w-10/12 overflow-y-auto m-auto inset-0 fixed p-4 md:p-10 z-50 text-theblue bg-white shadow h-max max-w-[700px] max-h-[90vh]">
        <div className="w-full h-auto bg-white md:p-10 p-2">
          <button
            className="close text-right mr-0 h-[5%] border-0 p-2 bg-white w-max-content absolute right-4 top-4"
            onClick={props.onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="none"
              viewBox="0 0 512 512"
            >
              <path
                fill="#014165"
                d="M256 21.333A234.667 234.667 0 1 0 490.666 256 234.94 234.94 0 0 0 256 21.333Zm105.578 310.102a21.332 21.332 0 0 1-23.24 34.946 21.325 21.325 0 0 1-6.925-4.781L256 286.165 180.565 361.6a21.335 21.335 0 0 1-29.905-.26 21.332 21.332 0 0 1-.26-29.905L225.834 256 150.4 180.565a21.332 21.332 0 0 1 23.24-34.946 21.325 21.325 0 0 1 6.925 4.781L256 225.835l75.413-75.435a21.325 21.325 0 0 1 15.16-6.512 21.32 21.32 0 0 1 15.27 6.248 21.34 21.34 0 0 1-.265 30.429L286.165 256l75.413 75.435Z"
              />
            </svg>
          </button>
          <div className="h-auto flex w-full">
            <div className="mx-auto w-full">
              <h2 className="pb-4 mt-10 sm:mt-0">IWC Watchmaking Class</h2>
              <div className="flex flex-col gap-4">
                <h5 className="py-6 m-auto w-2/3 sm:mt-0">
                  <strong>Please note space in the class is limited.</strong>
                  <br></br>
                  <br></br>
                  If guests will be joining, please book a separate appointment
                  for each guest including yourself.
                </h5>
                <div className="basis-1/2 w-full h-full">
                  <button
                    type="button"
                    onClick={handleClick("iwc-watchmaking")}
                    className="booking-button h-auto w-full"
                    id="iwc-watchmaking"
                  >
                    <h5>Proceed</h5>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IWCModal;
