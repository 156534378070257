import React, { useState, useEffect } from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import FormField from '../components/formField';
import LoadSpinner from '../assets/svgs/loadSpinner';
import fetchWithCSRF from '../lib/fetchWithCSRF';
import useCaptcha from '../hooks/useCaptcha';
import { validateWatchtime } from '../lib/validateForms';

function EventIntake({ setLoggedIn }) {
  const executeRecaptcha = useCaptcha();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    maisonOptins: {
      IWC: true,
      'Jaeger-LeCoultre': true,
      Panerai: true,
      Piaget: true,
      'Vacheron Constantin': true,
    },
  });
  const [formErrors, setFormErrors] = useState({});
  const [submitError, setSubmitError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const el = document.querySelector('.form-error');
    (el?.parentElement ?? el)?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [formErrors]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    setSubmitError('');
    setIsSubmitting(true);

    const sanitizedData = {
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      email: formData.email.trim().toLowerCase(),
      phone: formData.phone.trim(),
    };

    const validationErrors = validateWatchtime(sanitizedData);
    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      setIsSubmitting(false);
      return;
    }

    let maisonOptins = '';
    Object.keys(formData['maisonOptins']).forEach((maison) => {
      const isChecked = formData['maisonOptins'][maison];
      maisonOptins += isChecked ? 1 : 0;
    });

    try {
      const recaptchaToken = await executeRecaptcha();
      const data = {
        ...sanitizedData,
        maison_optin: maisonOptins,
        token: recaptchaToken,
      };

      const result = await fetchWithCSRF(
        '/api/event-registration',
        data,
        'POST'
      );
      const response = await result.json();
      if (!response.success) {
        setSubmitError(response.error || 'Server Error');
      } else {
        setSuccess(true);
        setFormData({
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          maisonOptins: {
            IWC: true,
            'Jaeger-LeCoultre': true,
            Panerai: true,
            Piaget: true,
            'Vacheron Constantin': true,
          },
        });
      }
    } catch (err) {
      setSubmitError('Server Error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="register font-playfair bg-theblue text-white p-0 m-0">
      <Navbar color="white" setLoggedIn={setLoggedIn} showLinks={false} />
      <div className=" py-20 max-[991.98px]:pt-10 w-full m-auto text-left bg-white">
        <h1 className="text-theblue text-center mb-12">
          Register for Watchtime
        </h1>
        {success ? (
          <p className="mx-auto text-center mt-5 min-h-[200px] sans text-theblue">
            Success! You're now registered for the Watchtime event.
          </p>
        ) : (
          <form
            className="bg-white max-[768px]:max-w-[90%] max-[1024px]:px-0 max-w-2xl m-auto px-8 pt-6 pb-8 mb-4"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
              <FormField
                label="First Name*"
                id="firstName"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    firstName: e.target.value,
                  })
                }
                errorMessage={formErrors.firstName}
              />
              <FormField
                label="Last Name*"
                id="lastName"
                value={formData.lastName}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    lastName: e.target.value,
                  })
                }
                errorMessage={formErrors.lastName}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
              <FormField
                label="Email Address*"
                id="email"
                type="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  })
                }
                errorMessage={formErrors.email}
              />
              <FormField
                label="Phone Number"
                id="phone"
                type="tel"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    phone: e.target.value,
                  })
                }
                errorMessage={formErrors.phone}
              />
            </div>
            <h5 className="text-theblue text-[17px] pb-2 pt-10 mb-0 text-left">
              I would like to receive marketing communications from the
              following Maisons
            </h5>
            <div className="grid justify-start md:justify-center text-left m-0 md:m-auto w-full text-theblue max-md:grid-cols-1 md:max-md:w-[70%] md:grid-cols-3 py-3">
              {Object.keys(formData["maisonOptins"]).map((brand) => (
                <div key={brand} className="flex items-center flex-nowrap m-2">
                  <input
                    className="mr-2 leading-tight"
                    type="checkbox"
                    name={brand}
                    id={brand}
                    value={brand}
                    checked={formData["maisonOptins"][brand]}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        maisonOptins: {
                          ...formData.maisonOptins,
                          [brand]: e.target.checked,
                        },
                      })
                    }
                  />
                  <label htmlFor={brand} className="my-auto">
                    {brand}
                  </label>
                </div>
              ))}
            </div>
            <p className="text-theblue text-center py-3 sans text-sm">
              We may send you this information using email, text, telephone, or
              post. We may also use your information to deliver personalized
              messages or advertising on social media or other digital
              platforms. You can ask us to stop marketing at any time. For
              further information about how we use your personal information,
              please see our
              <a
                href="privacy-policy"
                className="bg-transparent border-none outline-none text-theblue underline"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
            <div className="flex items-center justify-center flex-nowrap m-2">
              <label className="w-full flex items-center flex-nowrap text-theblue">
                <input
                  required
                  className="mr-2 leading-tight"
                  type="checkbox"
                />
                <span>
                  <h5 className="mb-0 text-[14px]">
                    I accept the Privacy Policy<strong>*</strong>
                  </h5>
                </span>
              </label>
            </div>
            <div className="grid py-3">
              <button
                className="bg-theblue border-0 text-white py-2 px-4 disabled:opacity-60"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <LoadSpinner /> : "Register"}
              </button>
              <div className={`mt-2 h-auto py-1`}>
                <p
                  className={`text-red-600 sans  text-sm text-center mt-2 h-auto p-2 ${
                    submitError && "server-error"
                  }`}
                >
                  {submitError}
                </p>
              </div>
            </div>
          </form>
        )}
      </div>

      <Footer color="blue" showLinks={false} />
    </div>
  );
}

export default EventIntake;
