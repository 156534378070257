import { Navigate, Outlet } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

const VerifiedRoutes = ({ isVerified }) => {
  const [searchParams] = useSearchParams();
  const maison = searchParams.get('maison');

  const trimmedPath = window.location.pathname.replace('/', '');

  if (isVerified) {
    return <Outlet />;
  }

  return (
    <Navigate
      to={`/please-verify${
        maison ? `?redirect=${trimmedPath}?maison=${maison}` : ''
      }`}
    />
  );
};
export default VerifiedRoutes;
