import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import video from '../assets/images/background-text_final_vid.mp4';
import AutoPlaySilentVideo from '../hooks/autoPlayVideo';

function Hero() {
  return (
    <div className="font-playfair bg-theblue  text-white p-0 m-0 relative">
      <div className="relative z-0 bg-[#93B5CD] w-full h-[39vw] max-h-[1000px]"></div>
      {/* <img
        src={gif}
        className="w-full absolute top-0 left-0 z-8"
        alt="Experience Time Background"
      /> */}
      <AutoPlaySilentVideo
        className="w-full absolute top-0 left-0 z-8"
        video={video}
      />

      {/* <img
        src={logo}
        alt="Experience Time Logo"
        className="w-[80%] md:w-[500px] absolute top-0 left-10 md:left-24 z-10 banner-logo"
      /> */}
    </div>
  );
}
export default Hero;
