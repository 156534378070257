import React from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

function PromotionTerms({ setLoggedIn, loggedIn, isAdmin }) {
  return (
    <div className="home font-playfair bg-theblue text-white p-0 m-0">
      <Navbar
        color="white"
        setLoggedIn={setLoggedIn}
        loggedIn={loggedIn}
        isAdmin={isAdmin}
      />
      <div className="bg-white">
        <h1 className="text-theblue">
          2024 Experience Time – The Rodeo Drive Edition<br></br> Promotion{" "}
        </h1>
        <div className="sans text-black my-12 max-w-[700px] mx-auto text-left">
          <h3 className="text-[16px] font-bold">Official Rules</h3>{" "}
          <p>
            NO PURCHASE OR PAYMENT NECESSARY TO ENTER OR WIN. A PAYMENT OR
            PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING. VOID WHERE
            PROHIBITED. ODDS OF WINNING DEPEND ON NUMBER OF ELIGIBLE ENTRIES
            RECEIVED. ENTRY OPEN ONLY TO LEGAL RESIDENTS OF THE 50 UNITED STATES
            AND THE DISTRICT OF COLUMBIA WHO ARE AT LEAST EIGHTEEN (18) YEARS OF
            AGE OR THE AGE OF MAJORITY IN THEIR JURISDICTION, WHICHEVER IS
            GREATER. VOID IN PUERTO RICO, ALL U.S. TERRITORIES AND POSSESSIONS,
            AND WHERE PROHIBITED BY LAW.
          </p>
          <p>
            {" "}
            THESE OFFICIAL RULES ARE A LEGALLY BINDING AGREEMENT BY AND BETWEEN
            YOU AND SPONSOR (DEFINED BELOW) THAT GOVERN ENTRY INTO THE
            PROMOTION. BY PARTICIPATING IN THIS PROMOTION, YOU AGREE TO ABIDE BY
            THESE OFFICIAL RULES. FAILURE TO COMPLY WITH THESE TERMS WILL VOID
            YOUR ENTRY.
          </p>
          <br />
          <p>
            <strong>1. Eligibility: </strong> This Promotion is open to any
            legal resident of the 50 United States or the District of Columbia
            who is at least eighteen (18) years of age or the age of majority in
            their place of residence, whichever is greater, as of the
            commencement of the Promotion Period (defined below). To enter, you
            must (i) visit the participating Boutiques to complete your Digital
            Passport during the Promotion Period, as detailed below, or (ii)
            follow the Alternate Method of Entry instructions below. (All
            capitalized terms are defined below.) Individuals who are employees,
            officers, representatives, or directors of Richemont North America,
            Inc. (“Sponsor”), and their respective parents, subsidiaries,
            affiliates and advertising and promotion agencies, and the immediate
            family members (spouses, domestic partners and parents, children and
            siblings and their spouses or domestic partners, regardless of where
            they live) or members of the same households (whether related or
            not) of such individuals are not eligible to win. All applicable
            federal, state, territorial and local rules apply. Participation
            constitutes entrant’s full and unconditional agreement to these
            Official Rules (the “Official Rules”) and Sponsor’s decisions, which
            are final and binding in all matters related to the Promotion.
            Winning a prize is contingent upon fulfilling all requirements set
            forth herein.
          </p>
          <br></br>
          <p>
            <strong>2. Timing: </strong> The Promotion begins on October 8, 2024
            and ends on October 13, 2024 (the “Promotion Period”). Sponsor is
            the official timekeeper for the Promotion.
          </p>
          <br></br>
          <p>
            <strong>3. How to Enter the Promotion: </strong> There are two (2)
            methods to submit an entry in this Promotion (an “Entry”):
          </p>
          <ul>
            <li>
              <strong>Entry Method #1:</strong> During the Promotion Period,
              eligible entrants shall (i) create an Experience Time account
              (“Account”) and create a “Digital Passport” by visiting{" "}
              <a
                href="https://www.experiencetimeamericas.com/login"
                className="text-inherit"
              >
                https://www.experiencetimeamericas.com/login
              </a>{" "}
              and following the on-screen instructions to create a “Digital
              Passport”; and (ii) visit each of the five (5) participating
              boutiques for the “Maisons” identified below (each, a “Boutique”;
              collectively, the “Boutiques”) during the Boutique’s Hours of
              Operation (provided in local time) and receive one (1) “stamp” on
              your Digital Passport at each Boutique by scanning the in-store QR
              code to complete your Digital Passport:
              <br></br>
              <br></br>
              <ol>
                <li>
                  IWC Schaffhausen Boutique
                  <ol type="a">
                    <li>
                      Address: 329 North Rodeo Drive, Beverly Hills, California
                      90210
                    </li>
                    <li>
                      Hours of Operation:
                      <ol type="i">
                        <li>Monday – Saturday: 10:00 am – 6:00 pm</li>
                        <li>Sunday: 12:00 pm – 5:00 pm</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <br></br>
                <li>
                  Jaeger-LeCoultre Boutique
                  <ol type="a">
                    <li>
                      Address: 430 North Rodeo Drive, Beverly Hills, California
                      90210
                    </li>
                    <li>
                      Hours of Operation:
                      <ol type="i">
                        <li>Monday – Saturday: 10:00 am – 6:00 pm</li>
                        <li>Sunday: 12:00 pm – 5:00 pm</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <br></br>
                <li>
                  Officine Panerai Boutique
                  <ol type="a">
                    <li>
                      Address: 441 North Rodeo Drive, Beverly Hills, California
                      90210
                    </li>
                    <li>
                      Hours of Operation:
                      <ol type="i">
                        <li>Monday – Saturday: 10:00 am – 6:00 pm</li>
                        <li>Sunday: 12:00 pm – 5:00 pm</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <br></br>
                <li>
                  Piaget Boutique
                  <ol type="a">
                    <li>
                      Address: 465 North Rodeo Drive, Beverly Hills, California
                      90210
                    </li>
                    <li>
                      Hours of Operation:
                      <ol type="i">
                        <li>Monday – Saturday: 10:00 am – 6:00 pm</li>
                        <li>Sunday: 12:00 pm – 5:00 pm</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <br></br>
                <li>
                  Vacheron Constantin Boutique
                  <ol type="a">
                    <li>
                      Address: 365 North Rodeo Drive, Beverly Hills, California
                      90210
                    </li>
                    <li>
                      Hours of Operation:
                      <ol type="i">
                        <li>Monday – Saturday: 10:00 am – 6:00 pm</li>
                        <li>Sunday: 12:00 pm – 5:00 pm</li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
              <br></br>
              <p>
                Upon your receipt of a “stamp” at each of the Boutiques and
                completing your Digital Passport (receiving a total of five (5)
                stamps) during the Promotion Period, you will be deemed to have
                submitted an “Entry” in the Promotion.
              </p>
            </li>
            <li>
              <strong>
                Entry Method #2 (“Alternate Method of Entry” or “AMOE”):{" "}
              </strong>{" "}
              During the Promotion Period, eligible entrants may enter the
              Promotion without completing Entry Method #1 above. To submit an
              AMOE, you must hand print your full name, street address, (no P.O.
              Boxes), day/evening/cellular phone number (if any), email address,
              and date of birth on a 3" x 5" piece of paper and mail it in an
              envelope with proper postage to: Richemont North America, Inc.,
              Attn: SWM, 645 Fifth Avenue, New York, New York 10022. All mail-in
              entries must be postmarked by 10/13/2024 and received by
              10/17/2024 – or “the end of the Promotion Period”. By following
              these steps, subject to the aforementioned timing requirements,
              you will be deemed to have submitted an “Entry” in the Promotion.
              All such entries shall become the exclusive property of Sponsor
              and none will be returned. Proof of sending an AMOE will not be
              deemed to be proof of Sponsor’s receipt of the AMOE.
            </li>
          </ul>
          <br></br>
          <p>
            <strong>4. Selection: </strong> After the conclusion of Promotion
            Period, Sponsor will choose five (5) potential winners at random
            from all Entries received during Promotion Period to win one of the
            Prizes (defined below) on or about October 18, 2024. The entrants
            selected as the potential winners must comply with all terms and
            conditions set forth in these Official Rules, and winning is
            contingent upon fulfilling all such requirements. All determinations
            are final and binding with respect to all matters relating to the
            Promotion.
          </p>
          <br></br>
          <p>
            <strong>
              5. Winner Notification and Declaration/Affidavit of
              Eligibility/Tax Forms:{" "}
            </strong>{" "}
            Sponsor or its designee shall notify each potential winner via
            telephone or e-mail, at the phone number or by e-mail provided in
            the Account/Entry, shortly after the end of Promotion Period on or
            around October 16, 2024. Potential winner may be required to
            complete a declaration of eligibility or an affidavit of eligibility
            and release (an “Affidavit of Eligibility”) as well as any
            additional required information, releases, or responses, and/or W-9
            Forms of the IRS (if required by IRS regulations) or other tax forms
            prior to the delivery of any Prize.
          </p>
          <p>
            If Sponsor requires each potential winner to complete an Affidavit
            of Eligibility, then each potential winner must return the fully
            executed Affidavit of Eligibility and provide all other requested
            information and documents to Sponsor within two (2) days (i.e., 48
            hours) following the date of first attempted notification. Failure
            to comply with this deadline may result in forfeiture of the Prize
            and selection of an alternate winner. If any potential winner is
            found to be ineligible or if any potential winner does not comply
            with the Official Rules, then the potential winner may also be
            disqualified, and an alternate potential Prize winner will be
            determined. If a potential winner cannot be contacted within
            forty-eight (48) hours after the first attempt to contact such
            potential winner, an alternate entrant may be randomly selected in
            his or her place from all Entries received.
          </p>
          <p>
            Sponsor will award the Prize to each winner by either (i) providing
            the Prize in-person to the winner at a Boutique or another nearby
            location designated by Sponsor, as determined in Sponsor’s sole
            discretion or (ii) shipping the Prize to each winner within
            approximately thirty (30) days from the selection date to the
            winner’s postal address (must be within the United States; P.O.
            Boxes not accepted) as provided to Sponsor in the winner’s
            Entry/Account.
          </p>
          <br />
          <p>
            <strong>Prizes:</strong>
            <ol>
              <li>
                one (1) winner will receive one (1) Panerai Borsone Weekender
                Bag (ARV: $495.00 USD);
              </li>
              <li>
                one (1) winner will receive one (1) Vacheron Constantin leather
                branded watch-roll (ARV: 499.00 USD);
              </li>
              <li>
                one (1) winner will receive one (1) Cashmere Throw in Piaget
                Blue (ARV: $350.00 USD);
              </li>
              <li>
                one (1) winner will receive one (1) F1 Racing Gloves signed by
                Lewis Hamilton (ARV: $250.00 USD);
              </li>
              <li>
                one (1) winner will receive one (1) Reverso Book (ARV: $195.00
                USD)
              </li>
            </ol>
            <p>
              (each, a “Prize”; collectively, the “Prizes”). There are five (5)
              Prizes total awarded in connection with the Promotion (total ARV
              of all Prizes: $1,789.00 USD). Awarding of a Prize is subject to
              verification of eligibility and compliance with these Official
              Rules. The odds of winning a Prize depend on the number of Entries
              received.{" "}
            </p>
            <p>
              The Prize and the redemption thereof are subject to terms and
              conditions specified by Sponsor. The Prize is awarded “as is” with
              no warranty or guarantee, either express or implied. Prize cannot
              be transferred, substituted, or redeemed for cash, or used in
              conjunction with any other competition or offer. Sponsor reserves
              the right, in its sole discretion, to substitute and/or modify a
              Prize with a prize of comparable or greater retail value, at its
              sole discretion. Only one (1) Prize is permitted per person and
              per family or household. All taxes on Prize and all costs and
              expenses related to acceptance and use of the Prize not specified
              in these Official Rules as being provided are the sole
              responsibility of winner. Prize will be awarded and delivered only
              to an address within the United States or Washington, D.C., unless
              the Sponsor, in its sole discretion, awards the Prize in-person to
              the winner. P.O. Boxes will not be accepted. If any mail is
              returned as undeliverable without a forwarding address, then the
              Prize may be forfeited, and an alternate winner may be selected at
              Sponsor’s discretion. All Prize details are at Sponsor’s sole
              discretion.
            </p>
          </p>
          <br></br>
          <p>
            <strong>7. Grant of Publicity:</strong> By participating in this
            Promotion, you agree to permit Sponsor and the Maisons, and their
            respective agents and designees to use your Entry, name, city and
            state/hometown address, voice, statement, image and/or other
            likeness for advertising, publicity and promotional purposes, in any
            manner, in any and all media, now or hereafter developed, including,
            but not limited to, within Sponsor’s and the Maisons’ websites and
            on Sponsor’s and the Maisons’ social media pages, in perpetuity,
            worldwide without further consideration (unless prohibited by law)
            or permission from, or notification to, you, and you further agree
            to execute any specific consent needed or requested by Sponsor,
            Maisons, or their designees in furtherance of such use.
          </p>
          <br></br>
          <p>
            <strong>8. General Conditions: </strong> By participating in this
            Promotion, you agree: [a] to be legally bound by these Official
            Rules and decisions of Sponsor, which shall be final and binding in
            all respects relating to the Promotion; and [b] to release, waive,
            discharge, indemnify, defend, and hold harmless Sponsor, and its
            respective parents, affiliates, subsidiaries, and advertising and
            promotion agencies, and all of their respective officers, directors,
            shareholders, partnership, members, principals, employees, agents,
            contractors, and suppliers (collectively, “Released Parties”) from
            any and all injuries, liability, losses, and damages of any kind
            arising out of or related to the participation in the Promotion or
            the acceptance, use or misuse of the Prize, including, without
            limitation, personal injury, death and property damage, and claims
            based on publicity rights, copyright, trademark, defamation or
            invasion of privacy. In the event of a dispute regarding the
            identity of the person submitting an Entry, the Entry will be deemed
            to be submitted by the natural person that is first named in the
            Account/Entry, as determined by Sponsor. If the identity of the
            person submitting an Entry cannot be resolved to Sponsor’s
            satisfaction, the affected Entry will be deemed ineligible.
          </p>
          <p>
            By entering the Promotion, you represent and warrant as follows: [a]
            the Entry is your own for you to win the Prize; [b] the Entry does
            not contain any false information; and [c] you did not attempt to
            submit more than the stated number of entries allowed under these
            Official Rules. Sponsor reserves the right to disqualify (and refuse
            to accept or remove) any Entry that Sponsor, in its sole discretion,
            deems to violate these Official Rules. Released Parties are not
            responsible for and shall not be liable for: [a] electronic,
            hardware or software program, network, Internet or computer
            malfunctions, failures, or difficulties of any kind, including
            without limitation, server malfunction or by any human error which
            may occur in the processing of Entries; [b] failed, incomplete,
            garbled or delayed computer transmissions; [c] late, lost,
            inaccurate, delayed, stolen, garbled, misdirected, illegible, or
            incomplete entries or for other errors or difficulties of any kind
            whether human, mechanical, electronic, computer, network,
            typographical, printing or otherwise relating to or in connection
            with the Promotion, including, without limitation, errors or
            difficulties which may occur in connection with the administration
            of the Promotion, the processing of Entries, or the announcement of
            the potential Prize winner; [d] any condition caused by events that
            may cause the Promotion to be delayed, cancelled, disrupted or
            corrupted. Released Parties are also not responsible for any
            incorrect or inaccurate information, whether caused by you or any
            other individuals, tampering, hacking, or by any equipment or
            programming associated with or utilized in the Promotion; or [e] any
            injury or damage to any person or their property related to or
            resulting from participating in this Promotion or otherwise
            receiving the Prize. Persons who tamper with or abuse any aspect of
            the Promotion, who act in an unsportsmanlike or disruptive manner,
            or who are otherwise in violation of these Official Rules, as solely
            determined by Sponsor, will be disqualified and all associated
            entries will be void. Sponsor reserves the right, to the fullest
            extent permissible under law, in its sole discretion, to cancel,
            terminate, modify or suspend the Promotion or any portion thereof
            should viruses, bugs or other causes (such as acts of God, work
            stoppages, accidents, acts or war or terrorism, civil or military
            disturbances, pandemics, epidemics, catastrophes, interruptions,
            fires, floods, or loss or malfunction of utilities, communications
            or computer services) corrupt or delay the administration, security
            or proper play of the Promotion, and limit Entries to those
            submitted prior to the action taken, or to proceed in such a manner
            as may be deemed fair and equitable by Sponsor in its sole
            discretion and, if terminated, to award the Prizes from all
            non-suspect, eligible Entries received prior to such action or as
            otherwise deemed fair and appropriate by Sponsor. These Official
            Rules cannot be modified or amended in any way except in a written
            document issued in accordance with law by a duly authorized
            representative of Sponsor. The invalidity or unenforceability of any
            provision of these Official Rules shall not affect the validity or
            enforceability of any other provision. In the event that any
            provision is determined to be invalid or otherwise unenforceable or
            illegal, these Official Rules shall otherwise remain in effect and
            shall be construed in accordance with their terms as if the invalid
            or illegal provision were not contained herein.
          </p>
          <p>
            Except where prohibited, entrant agrees that: (1) any and all
            claims, judgments and awards shall be limited to actual
            out-of-pocket costs incurred in entering this Promotion, and in no
            event will entrant be entitled to obtain attorneys’ fees or other
            legal costs; and (2) under no circumstances will entrant be
            permitted to obtain awards for, and entrant hereby waives all rights
            to claim, indirect, special, punitive, incidental, and consequential
            damages and any other damages, except as expressly provided above,
            and any and all rights to have damages multiplied or otherwise
            increased. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR
            EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
            THE ABOVE MAY NOT APPLY TO YOU.
          </p>
          <p>
            IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE ANY AND ALL RIGHTS UNDER
            SECTION 1542 OF THE CIVIL CODE OF THE STATE OF CALIFORNIA, WHICH
            PROVIDES AS FOLLOWS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS
            THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO
            EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND
            THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR
            HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.” You expressly
            waive and release any right or benefit which you have or may have
            under Section 1542 of the Civil Code of the State of California, to
            the full extent that you may waive all such rights and benefits
            pertaining to the matters released here. In connection with such
            waiver and relinquishment, you acknowledge that you are aware that
            you may hereafter discover claims presently unknown or unsuspected,
            or facts in addition to or different from those which you now know
            or believe to be true, with respect to the matters released herein;
            nevertheless, it is your intention through this release to fully and
            finally and forever settle and release all such matters and claims
            relative thereto, which do not exist, may exist or heretofore have
            existed between yourself and the Sponsor related to the Promotion.
            The release herein given shall be and remain in effect as a full and
            complete release of such claims and matters notwithstanding the
            discovery or existence of any such additional or different claims or
            facts relative thereto.
          </p>
          <p>
            Information collected from entrants is subject to the Privacy Policy
            set forth at{" "}
            <a
              href="https://www.experiencetimeamericas.com/privacy-policy"
              className="text-inherit"
            >
              https://www.experiencetimeamericas.com/privacy-policy
            </a>
          </p>
          <br></br>
          <p>
            <strong>9. Governing Law/Jurisdiction: </strong> Subject only to the
            following paragraph, the Promotion and these Official Rules,
            including, but not limited to, all issues and questions concerning
            the construction, validity, interpretation, and enforceability of
            these Official Rules, or the rights and obligations of you, other
            entrants, the potential winners, winners, and Sponsor, shall be
            exclusively governed by and construed in accordance with the laws of
            the State of New York, U.S.A. without giving effect to any choice of
            law or conflict of law rules (whether of the State of New York or
            any other jurisdiction), which would cause the application of the
            laws of any jurisdiction other than the State of New York. Subject
            only to the following paragraph, any and all disputes, claims and
            causes of action arising out of or connected with this Promotion or
            any prize awarded or not awarded shall be resolved individually,
            without resort to any form of class action, and exclusively by the
            Courts located in New York County, New York.
          </p>
          <br></br>
          <p>
            <strong>10. Sponsor: </strong> Richemont North America, Inc., 645
            Fifth Avenue, New York, New York 10022.
          </p>
          <br></br>
          <p>
            <strong>11. Winners’ List/Copy of Official Rules: </strong> To
            obtain any legally-required winners’ list (after the conclusion of
            the Promotion Period) or a copy of these Official Rules, send a
            written request, enclosed with a self-addressed envelope with the
            proper postage affixed, to: Richemont North America, Inc., Attn:
            SWM, 645 Fifth Avenue, New York, New York 10022 (FL, VT and WA
            residents may omit return postage). The request must be received
            within six months after the conclusion of the Promotion Period.
          </p>
          <br />
          <h2 className="uppercase text-lg">Contact us</h2>
          <p>
            If you have any questions or comments about these Promotion Terms, or
            matters generally, please contact us at the address provided below.
          </p>
          <br />
          <a
            href="mailto: 
ExperienceTimeAmericas@richemont.com"
            className="text-inherit"
          >
            ExperienceTimeAmericas@richemont.com
          </a>
        </div>
      </div>
      <Footer color="blue" />
    </div>
  );
}

export default PromotionTerms;
