import React, { useState } from 'react';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import LoadSpinner from '../assets/svgs/loadSpinner';
import fetchWithCSRF from '../lib/fetchWithCSRF';
import useCaptcha from '../hooks/useCaptcha';

function ForgotPassword({ setLoggedIn }) {
  const [email, setEmail] = useState('');
  const [validationError, setValidationError] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const executeRecaptcha = useCaptcha();

  const sendResetLink = async (e, data) => {
    e.preventDefault();
    setValidationError('');
    setError('');
    setSuccess('');
    if (!data) {
      setValidationError('Required');
      return;
    } else if (!/\S+@\S+\.\S+/.test(data)) {
      setValidationError('Not a valid email');
      return;
    }
    try {
      setLoading(true);
      const captchaToken = await executeRecaptcha();
      const result = await fetchWithCSRF(
        `/api/send-reset-link`,
        {
          email: data,
          token: captchaToken,
        },
        'POST'
      );
      const response = await result.json();
      if (response?.success) {
        setSuccess(true);
      } else {
        setError(response.error || 'Server Error');
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Server Error');
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="login font-playfair bg-theblue text-white p-0 m-0">
      <Navbar color="white" setLoggedIn={setLoggedIn} />
      <div className="py-10 w-full min-h-[73vh]  m-auto text-left bg-white">
        <h1 className="text-theblue text-center mb-3 text-2xl">
          Forgot Password
        </h1>
        <p className="text-theblue text-center max-w-[600px] mx-auto text-sm sans">
          Forgot your password? No worries! Just enter your email
          address below, and we'll send you a special link to reset
          it.
        </p>

        <form className="flex flex-col max-w-[500px] mx-auto mt-8 mb-16">
          <div className="flex flex-col justify-start text-left mb-4">
            <label
              htmlFor="email"
              className="block text-theblue text-inherit text-sm mb-2"
            >
              Email address
            </label>
            <input
              type="email"
              id="email"
              className="appearance-none border w-full py-2 px-3 text-theblue leading-tight focus:outline-[#014165]  focus:shadow-outline"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            ></input>
            <p className="text-red-600 sans  text-sm mt-2">
              {validationError}
            </p>
          </div>

          <button
            onClick={(e) => sendResetLink(e, email)}
            className="bg-theblue border-0 text-white py-2 px-4 disabled:opacity-60 sans text-sm"
            disabled={loading || success}
          >
            {loading ? <LoadSpinner /> : 'Send link to reset'}
          </button>
          {error && (
            <div className={`mt-2 h-auto`}>
              <p
                className={`text-red-600 sans  text-sm text-center mt-2 h-auto p-2 ${
                  error && 'server-error'
                }`}
              >
                {error}
              </p>
            </div>
          )}
          {success && (
            <div className="mt-2 min-h-8 h-auto p-2 bg-blue-50">
              <p className="text-blue-900 text-sm text-center mt-2 mb-1 sans">
                Success! If the email provided exists, you will
                receive an email with instructions to reset your
                password.
              </p>
            </div>
          )}
        </form>
      </div>
      <Footer color="blue" />
    </div>
  );
}

export default ForgotPassword;
