import { motion } from 'framer-motion';

const FadeUpSection = ({ classes, children }) => {
  return (
    <motion.div
      className={classes}
      initial={{
        opacity: 0,
        y: 20,
      }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: {
          duration: 1,
        },
      }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
};

export default FadeUpSection;
