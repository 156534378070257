import { Navigate, Outlet } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";

const PrivateRoutes = ({ isAuth }) => {
    const [searchParams] = useSearchParams();
    const maisonCode = searchParams.get("maison");
  if (isAuth) {
    return <Outlet />;
  }
if(maisonCode){
        return <Navigate
        to={`/login?redirect=digitalpassport?maison=${maisonCode}`}
        replace
      />
}else{


  return <Navigate to="/login" />;}
};
export default PrivateRoutes;
