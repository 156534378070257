import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { Navigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import Loginn from '../components/login';
function Login({ setLoggedIn, loggedIn, setVerified, setAdmin }) {
  const [searchParams] = useSearchParams();
  const redirectValue = searchParams.get('redirect');
  if (loggedIn) {
    if (redirectValue) {
      return <Navigate to={`/${redirectValue}`} replace />;
    } else {
      return <Navigate to="/home" replace />;
    }
  }
  return (
    <div className="login font-playfair bg-theblue text-white p-0 m-0">
      <Navbar color="white" setLoggedIn={setLoggedIn} />
      <Loginn
        setLoggedIn={setLoggedIn}
        loggedIn={loggedIn}
        setVerified={setVerified}
        setAdmin={setAdmin}
      />
      <Footer color="blue" />
    </div>
  );
}
export default Login;
