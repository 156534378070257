const FormField = ({
  label,
  id,
  type = 'text',
  required = false,
  value,
  onChange,
  errorMessage,
}) => (
  <div className="mb-4 text-left">
    <label className="block text-theblue text-sm mb-2" htmlFor={id}>
      {label}
    </label>

    <input
      className="appearance-none border f w-full py-2 px-3 text-theblue leading-tight focus:outline-[#014165] focus:rounded-none focus:outline-1 "
      id={id}
      type={type}
      required={required}
      value={value}
      autoComplete="nope"
      onChange={onChange}
    />

    {errorMessage && (
      <p className="form-error text-red-600 sans  text-sm mt-1">
        {errorMessage}
      </p>
    )}
  </div>
);

export default FormField;
