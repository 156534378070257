import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import Navbar from '../components/navbar';
import Boutiques from '../components/boutiques';
import Footer from '../components/footer';
import Maisons from '../components/maisoncards';
import PassportInfo from '../components/passportinfo';
import Hero from '../components/hero';
import map from '../assets/images/map.png';
import logo from '../assets/images/banner-logo.png';
import mobileMap from '../assets/images/mobile-map.jpg';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

function Home({ loggedIn, setLoggedIn, isAdmin }) {
  return (
    <div className="home font-playfair bg-theblue text-white p-0 m-0">
      <Navbar
        color="white"
        loggedIn={loggedIn}
        setLoggedIn={setLoggedIn}
        isAdmin={isAdmin}
      />

      <Hero />
      <Boutiques />
      <PassportInfo />
      <div className="bg-theblue px-16 py-36 text-center">
        <div className="max-w-[700px] mx-auto">
          <h2 className="text-white font-playfair mb-8 text-3xl">
            Complete your digital passport and enter a drawing for an exclusive
            prize.
          </h2>
          <p className="text-white sans">
            While visiting each of the five participating maisons, receive one
            stamp per boutique by scanning the in-store QR code to complete your
            digital passport. Once complete, you will be entered into a drawing
            to win an exclusive prize.
          </p>
          <p className="text-white sans">
            No purchase necessary. Open only to residents of the 50 United
            States and D.C. that are 18 years of age or older. Void where
            prohibited. For Official Rules, including entry instructions and
            AMOE option, please visit{" "}
            <a
              href="promotion-terms"
              className="bg-transparent border-none outline-none text-inherit underline"
              rel="noreferrer"
            >
              here{""}
            </a>
            .
          </p>
          <Nav.Link as={NavLink} to={"/home"}>
            <button className="bg-transparent border-solid border-2 hover:border-10 focus:border-10 border-white text-white px-5 py-2 mt-6">
              Digital Passport
            </button>
          </Nav.Link>
        </div>
      </div>
      <Maisons />
      <div className="bg-theblue p-0 flex justify-between">
        <div className="md:w-full hidden w-0 md:flex items-center justify-center">
          <img src={logo} alt="logo" className="min-w-[300px] w-1/2"></img>
        </div>
        <img
          src={map}
          alt="map of the boutiques"
          className="hidden md:block md:w-1/2"
        />
        <img
          src={mobileMap}
          alt="map of the boutiques"
          className="block md:hidden w-full"
        />
      </div>

      <Footer color="white" />
    </div>
  );
}
export default Home;
