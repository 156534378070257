import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { Navigate } from 'react-router-dom';
import DigitalPassportLog from '../components/digitalpassportlog';
import { useSearchParams } from 'react-router-dom';

function DigitalPassport({ loggedIn, setLoggedIn }) {
  const [searchParams] = useSearchParams();
  const maisonCode = searchParams.get('maison');
  if (!loggedIn) {
    return (
      <Navigate
        to={`/login?redirect=digitalpassport?maison=${maisonCode}`}
        replace
      />
    );
  }
  return (
    <div className="home font-playfair bg-theblue text-white p-0 m-0">
      {/* <Navbar color="white" loggedIn={loggedIn} setLoggedIn={setLoggedIn} /> */}
      <div className="bg-white h-10 w-full text-right flex items-center justify-end">
        <a className="bg-white border-none" href="/home">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="none"
            viewBox="0 0 512 512"
          >
            <path
              fill="#014165"
              d="M256 21.333A234.667 234.667 0 1 0 490.666 256 234.94 234.94 0 0 0 256 21.333Zm105.578 310.102a21.332 21.332 0 0 1-23.24 34.946 21.325 21.325 0 0 1-6.925-4.781L256 286.165 180.565 361.6a21.335 21.335 0 0 1-29.905-.26 21.332 21.332 0 0 1-.26-29.905L225.834 256 150.4 180.565a21.332 21.332 0 0 1 23.24-34.946 21.325 21.325 0 0 1 6.925 4.781L256 225.835l75.413-75.435a21.325 21.325 0 0 1 15.16-6.512 21.32 21.32 0 0 1 15.27 6.248 21.34 21.34 0 0 1-.265 30.429L286.165 256l75.413 75.435Z"
            />
          </svg>
        </a>
      </div>
      <DigitalPassportLog maison={maisonCode} />
      {/* <Footer color="blue" /> */}
    </div>
  );
}
export default DigitalPassport;
