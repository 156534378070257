import { useState, useEffect, useRef } from 'react';
import useEffectOnce from '../hooks/useEffectOnce';
import fetchUser from '../lib/fetchUser';
import fetchWithCSRF from '../lib/fetchWithCSRF';
import LoadSpinner from '../assets/svgs/loadSpinner';

const DigitalPassportLog = (props) => {
  const maison = props.maison;
  const [passport, setPassport] = useState('');
  const [error, setError] = useState('');
  const [passportHeight, setPassportHeight] = useState(0);

  let maisons = require('../jsons/maisons.json');

  async function updateStamp() {
    setError('');

    const data = {
      maison: maison,
    };

    try {
      const user = await fetchUser();
      if (user.data) {
        // Update stamps in our DB
        await fetchWithCSRF(
          `/api/stamp/${user.data.id}`,
          data,
          'POST'
        );
        return user.data;
      } else {
        setError('Something went wrong, please try again');
      }
    } catch (err) {
      setError(
        err.message || 'Something went wrong, please try again'
      );
    }
  }

  useEffectOnce(() => {
    if (maison) {
      updateStamp()
        .then((user) => {
          if (user) {
            setPassport(user.digital_passport_id);
          }
        })
        .catch((error) => {
          setError(
            error.message || 'Something went wrong, please try again'
          );
        });
    }
  }, [maison]);

  function resizeIframe() {
    setPassportHeight(window.innerHeight + 'px');
  }

  useEffect(() => {
    window.addEventListener('resize', resizeIframe, false);
    resizeIframe();
  }, []);

  return (
    <>
      {error ? (
        <div className="bg-white w-screen h-screen">{error}</div>
      ) : passport && maison ? (
        <iframe
          className="p-0 m-0 z-10 relative"
          width="100%"
          height={passportHeight}
          id="passport"
          frameBorder={0}
          allow="web-share"
          src={`https://passport.inspify.io/rodeoDrive2024?ref=${passport}&storeId=${maisons[maison]}`}
          title="Digital Passport"
        ></iframe>
      ) : (
        <div className="w-full h-full m-auto inset-0 p-0 z-50 text-theblue bg-white absolute top-[90px] left-0 z-1">
          <div className="w-full flex items-center justify-center h-full m-0 p-0">
            <LoadSpinner size="w-12 h-12" />
          </div>
        </div>
      )}
    </>
  );
};
export default DigitalPassportLog;
