import React from 'react';
import logo from '../assets/images/experiencelogo.png';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import whitelogo from '../assets/images/experiencelogo-w.png';
import { useNavigate } from 'react-router-dom';
import fetchWithCSRF from '../lib/fetchWithCSRF';

function NavigationBar({
  showPassportLink = true,
  showLinks = true,
  ...props
}) {
  let nameofclass;
  let thelogo;
  let theme;
  let logoclass;
  let linkclass;
  let overalllinks;

  const navigate = useNavigate();

  if (props.color === 'white') {
    nameofclass =
      'font-playfair static bottom-0 max-[991.98px]:p-0 text-theblue bg-white';

    thelogo = logo;

    overalllinks = 'max-[991.98px]:bg-theblue mt-2';

    linkclass =
      'text-inherit max-[991.98px]:border-b-[1px] max-[991.98px]:border-solid max-[991.98px]:text-white max-[991.98px]:hover:text-white max-[991.98px]:active:text-white max-[991.98px]:focus:text-white max-[991.98px]:border-b-[#011F30] sans';

    theme = 'light';
  } else if (props.color === 'blue') {
    nameofclass =
      'font-playfair static bottom-0 max-[991.98px]:p-0 text-white max-[991.98px]:bg-theblue';

    thelogo = whitelogo;

    linkclass =
      ' text-inherit max-[991.98px]:border-b-[1px] max-[991.98px]:border-solid max-[991.98px]:text-theblue max-[991.98px]:hover:text-theblue max-[991.98px]:hover:bg-sky-100 max-[991.98px]:border-b-theblue sans';

    overalllinks = 'max-[991.98px]:bg-white mt-2';

    theme = 'dark';
  } else if (props.color === 'light') {
    nameofclass =
      'font-playfair static bottom-0 max-[991.98px]:p-0 text-theblue bg-[#93B5CD]';

    logoclass = `text-inherit hidden`;

    linkclass =
      'text-inherit max-[991.98px]:border-b-[1px] max-[991.98px]:border-solid max-[991.98px]:text-theblue max-[991.98px]:hover:text-theblue max-[991.98px]:hover:bg-sky-100 max-[991.98px]:border-b-theblue sans';

    overalllinks = 'max-[991.98px]:bg-white mt-2';

    thelogo = whitelogo;

    theme = 'dark';
  }

  const handleLogout = () => {
    fetchWithCSRF('/logout', null, 'POST')
      .then((res) => {
        sessionStorage.setItem('auth', false);
        sessionStorage.setItem('email_verified', false);
        sessionStorage.setItem('is_admin', false);
        props.setLoggedIn(false);
        navigate('/login');
      })

      .catch((err) => {
        console.error('Error during logout:', err);
      });
  };

  return (
    <div className={nameofclass}>
      <Navbar
        data-bs-theme={theme}
        expand="lg"
        className={!showLinks && 'justify-center'}
      >
        <Navbar.Brand
          className={`w-1/2 max-w-[300px] max-[991.98px]:m-6 active:border-none`}
        >
          <Nav.Link
            className={logoclass}
            as={NavLink}
            to={showLinks ? '/' : null}
          >
            <img
              src={thelogo}
              className="w-full"
              alt="Experience Time Logo"
            />
          </Nav.Link>
        </Navbar.Brand>
        {showLinks && (
          <>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="border-0 max-[991.98px]:m-6 "
            />

            <Navbar.Collapse
              className={overalllinks}
              id="basic-navbar-nav"
            >
              <Nav className="ml-auto text-inherit">
                {props.loggedIn && showPassportLink && (
                  <Nav.Link
                    className={linkclass}
                    as={NavLink}
                    to={'/home'}
                  >
                    Dashboard
                  </Nav.Link>
                )}
                {!props.loggedIn && (
                  <Nav.Link
                    className={linkclass}
                    as={NavLink}
                    to={'/register'}
                  >
                    Register
                  </Nav.Link>
                )}
                {props.loggedIn && props.isAdmin ? (
                  <Nav.Link
                    className={linkclass}
                    as={NavLink}
                    to={'/admin'}
                  >
                    Admin
                  </Nav.Link>
                ) : null}

                {props.loggedIn ? (
                  <Nav.Link
                    className={linkclass}
                    onClick={handleLogout}
                  >
                    Logout
                  </Nav.Link>
                ) : (
                  <Nav.Link
                    className={linkclass}
                    as={NavLink}
                    to={'/login'}
                  >
                    Login
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Navbar>
    </div>
  );
}

export default NavigationBar;
