import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import panerai from '../assets/images/panerai.svg';
import piaget from '../assets/images/piaget.svg';
import iwc from '../assets/images/iwc.svg';
import jaeger from '../assets/images/jaeger.svg';
import vacheronconstantin from '../assets/images/vacheron-constantin.svg';
import FadeUpSection from './fadeUpSection';
import { motion } from 'framer-motion';

function Boutiques() {
  return (
    <div className="font-playfair bg-white text-theblue space-y-10 flex-col p-16 md:p-28">
      <FadeUpSection>
        <h4 className="w-[70%] max-[1024px]:w-full mx-auto mb-8 max-w-[750px]">
          For the first time in the Americas, Experience Time will
          take place in Beverly Hills from October 8 - 13, 2024
        </h4>
        <br></br>
        <p className="w-[70%] max-[1024px]:w-full m-auto max-w-[750px] sans">
          The following participating Maisons are pleased to
          exclusively invite you to their Rodeo Drive flagship
          boutiques.
        </p>
      </FadeUpSection>

      <motion.div
        initial={{
          opacity: 0,
          x: '-100%',
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 1,
          },
        }}
        viewport={{ once: true }}
        className="flex flex-wrap flex-row items-center justify-center w-[90%] max-[1024px]:w-full mx-auto gap-4"
      >
        <div className="">
          <img
            src={iwc}
            className="max-[820px]:max-w-[100px] max-w-[150px] p-2.5"
            alt="Brand Logo"
          />
        </div>
        <div className="">
          <img
            src={jaeger}
            className="max-[820px]:max-w-[100px] max-w-[150px] p-2.5 "
            alt="Brand Logo"
          />
        </div>
        <div className="">
          <img
            src={panerai}
            className="max-[820px]:max-w-[100px] max-w-[150px] p-2.5 "
            alt="Brand Logo"
          />
        </div>
        <div className="">
          <img
            src={piaget}
            className="max-[820px]:max-w-[100px] max-w-[150px] p-2.5 "
            alt="Brand Logo"
          />
        </div>

        <div className="">
          <img
            src={vacheronconstantin}
            className="max-[820px]:max-w-[100px] max-w-[150px] p-2.5"
            alt="Brand Logo"
          />
        </div>
      </motion.div>
    </div>
  );
}
export default Boutiques;
