import React from 'react';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSearchParams } from 'react-router-dom';
import useCaptcha from '../hooks/useCaptcha';
import { useNavigate } from 'react-router-dom';
import LoadSpinner from '../assets/svgs/loadSpinner';
import fetchWithCSRF from '../lib/fetchWithCSRF';
import { validateRegistration } from '../lib/validateForms';
import FormField from './formField';

function Register({ setLoggedIn }) {
  let countries = require('../jsons/countries.json');

  const [searchParams] = useSearchParams();
  const redirectValue = searchParams.get('redirect');
  const navigate = useNavigate();

  const executeRecaptcha = useCaptcha();

  let maisons = document.getElementsByName('maison_optin');

  for (let i = 0; i < maisons.length; i++) {
    maisons[i].checked = true;
  }
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    birthdate: '',
    city: '',
    phone: '',
    maison_optin: {
      IWC: true,
      'Jaeger-LeCoultre': true,
      Panerai: true,
      Piaget: true,
      'Vacheron Constantin': true,
    },
    confirmpassword: '',
    passion_points: {
      Travel: false,
      Sailing: false,
      Wine: false,
      'Culinary / Dining': false,
      Fashion: false,
      Cigars: false,
      Spirits: false,
      'Classic Cars': false,
      Jewelry: false,
      Art: false,
      Motorsports: false,
      Golf: false,
      Aviation: false,
      Cinema: false,
    },
    country: 'United States',
  });

  useEffect(() => {
    const el = document.querySelector('.form-error');
    (el?.parentElement ?? el)?.scrollIntoView({ behavior: 'smooth' });
  }, [errors]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };
  const handleCountryChange = (e) => {
    const { country } = e;
    setFormData({ ...formData, country: country });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const sanitizedFormData = {
      ...formData,
      firstname: formData.firstname.trim(),
      lastname: formData.lastname.trim(),
      email: formData.email.trim().toLowerCase(),
      password: formData.password.trim(),
      confirmpassword: formData.confirmpassword.trim(),
      city: formData.city.trim(),
      phone: formData.phone.trim(),
      country: formData.country.trim(),
    };

    let passionPoints = '';
    Object.keys(formData['passion_points']).forEach((interest) => {
      const isChecked = formData['passion_points'][interest];
      passionPoints += isChecked ? `${interest},` : '';
    });
    var trimpassion = passionPoints.replace(/(^\s*,)|(,\s*$)/g, '');

    let maisonOptins = '';
    Object.keys(formData['maison_optin']).forEach((maison) => {
      const isChecked = formData['maison_optin'][maison];
      maisonOptins += isChecked ? 1 : 0;
    });

    const validationErrors = validateRegistration(sanitizedFormData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsSubmitting(false);
      return;
    }

    try {
      setError('');
      setErrors('');
      const recaptchaToken = await executeRecaptcha();
      const data = {
        ...sanitizedFormData,
        passion_points: trimpassion,
        maison_optin: maisonOptins,
        birthdate: formData.birthdate ? formData.birthdate : null,
        token: recaptchaToken,
        redirectValue: redirectValue,
      };

      fetchWithCSRF('/api/clients', data, 'POST')
        .then(async (result) => {
          const resBody = await result.json();

          // Need to redirect to verify if not verified
          if (!result.ok) {
            setError(
              resBody.error ||
                'Something went wrong, please try again'
            );
            setIsSubmitting(false);
          } else if (!resBody.success) {
            setError(resBody.error || 'Something went wrong');
            setIsSubmitting(false);
          } else {
            sessionStorage.setItem('auth', true);
            sessionStorage.setItem('email_verified', false);
            setLoggedIn(true);
            if (redirectValue) {
              navigate(`/please-verify?redirect=${redirectValue}`);
            } else {
              navigate(`/please-verify`);
            }
            setIsSubmitting(false);
          }
        })
        .catch((err) => {
          if (err.status === 409) {
            setError('Email address already exists');
          } else if (err.message) {
            setError(err.message);
          } else {
            setError('Something went wrong, please try again');
          }
          setIsSubmitting(false);
        });
    } catch (err) {
      setError(
        err.message || 'Something went wrong, please try again'
      );
      setLoggedIn(false);
      setIsSubmitting(false);
    }
  };

  return (
    <div className=" py-20 max-[991.98px]:pt-10 w-full m-auto text-left bg-white">
      <h1 className="text-theblue text-center mb-3">Register</h1>
      <form
        className="bg-white max-[768px]:max-w-[90%] max-[1024px]:px-0 max-w-2xl m-auto px-8 pt-6 pb-8 mb-4"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
          <FormField
            label="First Name*"
            id="firstname"
            value={formData.firstname}
            onChange={handleInputChange}
            errorMessage={errors.firstname}
          />
          <FormField
            label="Last Name*"
            id="lastname"
            value={formData.lastname}
            onChange={handleInputChange}
            errorMessage={errors.lastname}
          />
        </div>
        <FormField
          label="Email Address*"
          id="email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          errorMessage={errors.email}
        />
        <FormField
          label="Password*"
          id="password"
          type="password"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          value={formData.password}
          onChange={handleInputChange}
          errorMessage={errors.password}
        />
        <FormField
          label="Confirm Password*"
          id="confirmpassword"
          type="password"
          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
          value={formData.confirmpassword}
          onChange={handleInputChange}
          errorMessage={errors.confirmpassword}
        />
        <FormField
          label="Birthdate"
          id="birthdate"
          type="date"
          value={formData.birthdate}
          onChange={handleInputChange}
          errorMessage={errors.birthdate}
        />
        <FormField
          label="Phone Number"
          id="phone"
          value={formData.phone}
          onChange={handleInputChange}
          errorMessage={errors.phone}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
          <FormField
            label="City of Residence"
            id="city"
            value={formData.city}
            onChange={handleInputChange}
            errorMessage={errors.city}
          />
          <div className="select-country">
            <label
              className="block text-theblue text-sm mb-2"
              htmlFor="country"
            >
              Country of Residence*
            </label>
            <Select
              className="appearance-none  w-full css-13cymwt-control:border-radius:[0px] text-theblue leading-tight focus:outline-[#014165] focus:shadow-outline"
              name="countries"
              options={countries}
              onChange={handleCountryChange}
              value={countries.country}
              defaultValue={{
                country: "United States",
                abbreviation: "US",
              }}
              getOptionLabel={(option) => option.country}
              getOptionValue={(option) => option.abbreviation}
            />
            {errors.country && (
              <p className="form-error text-red-600 sans  text-sm mt-1">
                {errors.country}
              </p>
            )}
          </div>
        </div>

        <h5 className="text-theblue pb-2 pt-8 mb-0 text-[17px]">
          What are your interests? (Select all that apply)
        </h5>
        <div className="grid justify-center text-left m-0 md:m-auto w-full text-theblue max-md:grid-cols-2 sm:max-md:w-[70%] grid-cols-3 py-3">
          {Object.keys(formData["passion_points"]).map((interest) => (
            <div key={interest} className="flex items-center flex-nowrap m-2">
              <input
                className="mr-2 leading-tight"
                type="checkbox"
                name={interest}
                id={interest}
                value={interest}
                checked={formData["passion_points"][interest]}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    passion_points: {
                      ...formData.passion_points,
                      [interest]: e.target.checked,
                    },
                  })
                }
              />
              <label htmlFor={interest} className="my-auto">
                {interest}
              </label>
            </div>
          ))}
        </div>
        <h5 className="text-theblue text-[17px] pb-2 pt-10 mb-0">
          I would like to receive marketing communications from the following
          Maisons:
        </h5>
        <div className="grid justify-start md:justify-center text-left m-0 md:m-auto w-full text-theblue max-md:grid-cols-1 md:max-md:w-[70%] md:grid-cols-3 py-3">
          {Object.keys(formData["maison_optin"]).map((brand) => (
            <div key={brand} className="flex items-center flex-nowrap m-2">
              <input
                className="mr-2 leading-tight"
                type="checkbox"
                name={brand}
                id={brand}
                value={brand}
                checked={formData["maison_optin"][brand]}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    maison_optin: {
                      ...formData.maison_optin,
                      [brand]: e.target.checked,
                    },
                  })
                }
              />
              <label htmlFor={brand} className="my-auto">
                {brand}
              </label>
            </div>
          ))}
        </div>
        <p className="text-theblue text-center py-3 text-sm agreement sans">
          We may send you this information using email, text, telephone, or
          post. We may also use your information to deliver personalized
          messages or advertising on social media or other digital platforms.
          You can ask us to stop marketing at any time. For further information
          about how we use your personal information, please see our{" "}
          <a
            href="privacy-policy"
            className="bg-transparent border-none outline-none text-theblue underline"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>
        <div className="flex items-center justify-center flex-nowrap m-2">
          <label className="w-full flex items-center flex-nowrap text-theblue">
            <input required className="mr-2 leading-tight" type="checkbox" />
            <span>
              <p className="mb-0 text-regular text-[14px]">
                I accept the Privacy Policy<strong>*</strong>
              </p>
            </span>
          </label>
        </div>
        <p className="text-theblue text-center py-3 text-sm agreement sans">
          By entering, you, the participant, have affirmatively reviewed,
          accepted, and agreed to all of the{" "}
          <a
            href="promotion-terms"
            className="bg-transparent border-none outline-none text-theblue underline"
            target="_blank"
            rel="noreferrer"
          >
            Official Rules{" "}
          </a>
          and confirm that you are 18 years of age or older (or age of majority
          in your jurisdiction, whichever is greater) and a legal resident of
          the 50 United States or Washington D.C. and eligible to enter. .
        </p>
        <div className="flex items-center justify-center flex-nowrap m-2">
          <label className="w-full flex items-center flex-nowrap text-theblue">
            <input required className="mr-2 leading-tight" type="checkbox" />
            <span>
              <p className="mb-0 text-regular text-[14px]">
                I accept the Promotion Terms<strong>*</strong>
              </p>
            </span>
          </label>
        </div>
        <div className="grid py-3">
          <button
            className="bg-theblue border-0 text-white py-2 px-4 disabled:opacity-60"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <LoadSpinner /> : "Register"}
          </button>
          <p className="p-3 m-auto text-theblue sans text-[14px]">
            Already have an account?&nbsp;
            <a
              className="inline-block align-baseline text-sm text-theblue hover:text-blue-800"
              href="/login"
            >
              Sign In
            </a>
          </p>
        </div>
        <div className={`mt-2 h-auto`}>
          <p
            className={`text-red-600 sans  text-sm text-center mt-2 h-auto p-2 ${
              error && "server-error"
            }`}
          >
            {error}
          </p>
        </div>
      </form>
    </div>
  );
}
export default Register;
