import { useState, useEffect } from 'react';

const BookingModal = (props) => {
  let urls = require('../jsons/urls.json');

  const [bookingURL, setBookingURL] = useState('');

  useEffect(() => {
    setBookingURL(
      `https://outlook.office365.com/book/ExperienceTimeAmericas@richemont.com/s/${
        urls[props.brand]
      }`
    );
  }, [props.show, urls, props.brand]);

  if (!props.show) {
    return null;
  }

  return (
    <div className="w-full h-full m-auto inset-0 fixed p-0 z-50 text-theblue bg-white">
      <div className="w-full h-full m-0 p-0">
        <button
          className="close text-right m-0 h-auto p-2 w-full border-0 relative bg-white shadow-lg"
          onClick={props.onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="none"
            viewBox="0 0 512 512"
          >
            <path
              fill="#014165"
              d="M256 21.333A234.667 234.667 0 1 0 490.666 256 234.94 234.94 0 0 0 256 21.333Zm105.578 310.102a21.332 21.332 0 0 1-23.24 34.946 21.325 21.325 0 0 1-6.925-4.781L256 286.165 180.565 361.6a21.335 21.335 0 0 1-29.905-.26 21.332 21.332 0 0 1-.26-29.905L225.834 256 150.4 180.565a21.332 21.332 0 0 1 23.24-34.946 21.325 21.325 0 0 1 6.925 4.781L256 225.835l75.413-75.435a21.325 21.325 0 0 1 15.16-6.512 21.32 21.32 0 0 1 15.27 6.248 21.34 21.34 0 0 1-.265 30.429L286.165 256l75.413 75.435Z"
            />
          </svg>
        </button>
        {bookingURL && (
          <iframe
            className="w-full h-[95%] p-0 m-0"
            src={bookingURL}
            title="Booking"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default BookingModal;
