import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import piagetwatch from '../assets/images/piagetwatch.jpg';
import paneraiwatch from '../assets/images/paneraiwatch.jpg';
import iwcwatch from '../assets/images/iwcwatch.jpg';
import jaegerwatch from '../assets/images/jaegerwatch.jpg';
import vacheronwatch from '../assets/images/vacheronwatch.jpg';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import panerai from '../assets/images/panerai.svg';
import piaget from '../assets/images/piaget-w.svg';
import iwc from '../assets/images/iwc.svg';
import jaeger from '../assets/images/jaeger-w.svg';
import whitevacheronconstantin from '../assets/images/vacheron-constantin.svg';
import FadeUpSection from './fadeUpSection';
import Accordion from 'react-bootstrap/Accordion';
import PhoneIcon from '../assets/svgs/phoneIcon';
import EmailIcon from '../assets/svgs/emailIcon';

function Maisons() {
  const TabComponent = ({ store, color }) => {
    return (
      <div
        className={`accordion-container accordion-container--${color} sans`}
      >
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey={store}>
            <Accordion.Header>Boutique Information</Accordion.Header>
            <Accordion.Body>
              {store === 'Piaget' && (
                <div className="">
                  <br></br>
                  <p className="mb-4">
                    <strong>Contact:</strong>
                    <br />
                    <span className="mb-2 inline-block text-[14px]">
                      <PhoneIcon fill="white" classes="mr-2" />
                      +1 (424) 332-4280
                    </span>
                    <br />
                    <span className="text-[14px]">
                      <EmailIcon fill="white" classes="mr-2" />
                      rodeodrive.boutique@piaget.com
                    </span>
                  </p>
                  <p>
                    <strong>Address:</strong> <br></br>
                  </p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.96611740964!2d-118.40688802376746!3d34.070382816771314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bd13bd6db86b%3A0x61eb93d8a2afdb8f!2sPiaget%20Boutique%20Beverly%20Hills%20-%20Rodeo%20Drive!5e0!3m2!1sen!2sus!4v1722983860441!5m2!1sen!2sus"
                    width="100%"
                    title="Map of boutique"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                  <p className="mt-4 text-[14px]">
                    <strong>Hours:</strong> <br></br>
                    Monday - Saturday: 10am – 6pm<br></br>Sunday: 12pm
                    – 5pm
                  </p>
                </div>
              )}
              {store === 'Panerai' && (
                <div className="">
                  <br></br>
                  <p className="mb-4">
                    <strong>Contact:</strong>
                    <br />
                    <span className="mb-2 inline-block text-[14px]">
                      <PhoneIcon fill="#014165" classes="mr-2" />
                      +1 (310) 228 1515
                    </span>
                    <br />
                    <span className="text-[14px]">
                      <EmailIcon fill="#014165" classes="mr-2" />
                      BeverlyHills@Panerai.com
                    </span>
                  </p>
                  <p>
                    <strong>Address:</strong> <br></br>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6609.977823507991!2d-118.4081804651123!3d34.0697985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bc0788bcbc73%3A0x55d129c065043ea5!2sPanerai%20Boutique!5e0!3m2!1sen!2sus!4v1722984043868!5m2!1sen!2sus"
                      width="100%"
                      height="300"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      title="Map of boutique"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </p>
                  <p className="mt-4 text-[14px]">
                    <strong>Hours:</strong>
                    <br></br>
                    Monday - Saturday: 10am - 6pm<br></br>Sunday: 12pm
                    – 5pm
                  </p>
                </div>
              )}
              {store === 'IWC' && (
                <div className="">
                  <br></br>
                  <p className="mb-4">
                    <strong>Contact:</strong>
                    <br />
                    <span className="mb-2 inline-block text-[14px]">
                      <PhoneIcon fill="#014165" classes="mr-2" />
                      +1 (310) 734-0520
                    </span>
                    <br />
                    <span className="text-[14px]">
                      <EmailIcon fill="#014165" classes="mr-2" />
                      us.events@iwc.com
                    </span>
                  </p>
                  <p>
                    <strong>Address:</strong> <br></br>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.051880315962!2d-118.4045698825561!3d34.068184300000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bc0788b74975%3A0xa8aea82b9a4dd018!2sIWC%20Schaffhausen%20Flagship%20Boutique%20-%20Beverly%20Hills!5e0!3m2!1sen!2sus!4v1722984086821!5m2!1sen!2sus"
                      width="100%"
                      height="300"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      title="Map of boutique"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </p>
                  <p className="mt-4 text-[14px]">
                    <strong>Hours:</strong> <br></br>
                    Monday - Saturday: 10am - 6pm<br></br>Sunday: 12pm
                    – 5pm
                  </p>
                </div>
              )}
              {store === 'Vacheron' && (
                <div className="">
                  <br></br>
                  <p className="mb-4">
                    <strong>Contact:</strong>
                    <br />
                    <span className="text-[14px]">
                      <PhoneIcon fill="#014165" classes="mr-2" />
                      +1 310-598-2026
                    </span>
                    <br />
                    <span></span>
                  </p>
                  <p>
                    <strong>Address:</strong> <br></br>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.0215900422563!2d-118.4029159!3d34.068960799999985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bbf8743d04ef%3A0xd64eefd16c3d717e!2s365%20N%20Rodeo%20Dr%2C%20Beverly%20Hills%2C%20CA%2090210!5e0!3m2!1sen!2sus!4v1722984155527!5m2!1sen!2sus"
                      width="100%"
                      height="300"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      title="Map of boutique"
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </p>
                  <p className="text-[14px]">
                    <strong>Hours:</strong>
                    <br></br>
                    Monday - Saturday 10:00 - 18:00<br></br>Sunday
                    12:00 - 17:00
                  </p>
                </div>
              )}
              {store === 'Jaeger' && (
                <div className="">
                  <br></br>
                  <p className="mb-4">
                    <strong>Contact:</strong>
                    <br />
                    <span className="mb-2 inline-block text-[14px]">
                      <PhoneIcon fill="white" classes="mr-2" />
                      +1 (310) 734-0525
                    </span>
                    <br />
                    <span className="text-[14px]">
                      <EmailIcon fill="white" classes="mr-2" />
                      beverlyhills.jlc@jaeger-lecoultre.com
                    </span>
                  </p>
                  <p>
                    <strong>Address:</strong> <br></br>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.9836532025606!2d-118.40331420000004!3d34.0699333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bc07897bdde3%3A0x2f58b2655839a8c!2sJaeger-LeCoultre%20Boutique%20-%20Beverly%20Hills!5e0!3m2!1sen!2sus!4v1722984198616!5m2!1sen!2sus"
                      width="100%"
                      height="300"
                      style={{ border: 0 }}
                      title="Map of boutique"
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </p>
                  <p className="mt-4 text-[14px]">
                    <strong>Hours:</strong> <br></br>
                    Monday - Saturday: 10am - 6pm<br></br>Sunday: 12pm
                    – 5pm
                  </p>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  };

  return (
    <div className="p-0 m-0 ">
      <div className="flex font-playfair flex-wrap-reverse text-left p-0 m-0 items-stretch  text-theblue bg-white">
        <div className="w-1/2 max-[1000px]:w-full ">
          <img
            className="w-full h-full object-cover"
            loading="lazy"
            src={iwcwatch}
            alt="Watch"
          />
        </div>
        <FadeUpSection classes="card-text w-1/2 max-[1000px]:w-full max-[1000px]:text-center max-[1000px]:px-10 m-auto p-20">
          <img className="w-[200px] mb-5" src={iwc} alt="Logo of Brand" />
          <p className="mb-0 sans">
            A Tribute to Eternity with IWC Schaffhausen Immerse yourself in the
            eternity of Watchmaking during Rodeo Watch Week.
          </p>
          <p className="mb-0 sans">
            IWC is pleased to host daily watchmaking classes and product
            presentations, as well as happy hours dedicated to share our passion
            for watches.
          </p>
          <p className="mb-0 sans">
            During this week, discover the Record-breaking IWC Portugieser
            Eternal Calendar and learn more about the new collection recently
            launched during Watches & Wonders.
          </p>
          <div className="flex  max-[1000px]:m-auto">
            <TabComponent store={"IWC"} color="white" />
          </div>
          <Nav.Link as={NavLink} to={"/home"}>
            <button className="bg-transparent border-solid border-theblue text-theblue px-4 py-1.5 mt-6">
              Book Now
            </button>
          </Nav.Link>
        </FadeUpSection>
      </div>

      <div className="flex font-playfair  flex-wrap text-left p-0 m-0 items-stretch  text-white bg-theblue">
        <FadeUpSection classes="card-text m-auto max-[1000px]:w-full max-[1000px]:text-center w-1/2 p-20 max-[1000px]:px-10">
          <img
            className="w-3/4 max-w-[250px] mb-5"
            src={jaeger}
            loading="lazy"
            alt="Logo of Brand"
          />
          <p className="mb-0 sans">
            Jaeger-LeCoultre invites you to discover “The Precision Pioneer”
            Travelling Collection through a boutique tour to discover the
            principles of precision through fascinating watchmaking stories of
            Production, Chronometry, Regulating Organs and Complications. This
            week, the Boutique will also host the latest timepieces of the
            Grande Maison, including the Duometre Collection recently launched
            at Watches & Wonders.
          </p>
          <p className="mb-0 sans">
            During this boutique visit, guests are also able to sign up for
            future private watchmaking experiences.
          </p>
          <div className="flex  max-[1000px]:m-auto">
            <TabComponent store={"Jaeger"} color="blue" />
          </div>
          <Nav.Link as={NavLink} to={"/home"}>
            <button className="bg-transparent border-solid border-white text-white px-4 py-1.5 mt-6">
              Book Now
            </button>
          </Nav.Link>
        </FadeUpSection>
        <div className="w-1/2 max-[1000px]:w-full ">
          <img
            className="w-full h-full  object-cover"
            src={jaegerwatch}
            alt="Watch"
          />
        </div>
      </div>

      <div className="flex font-playfair flex-wrap-reverse m-a items-stretch text-left p-0 m-auto text-theblue bg-white">
        <div className="w-1/2 bg max-[1000px]:w-full">
          <img
            className="w-full h-full object-cover "
            src={paneraiwatch}
            loading="lazy"
            alt="Watch"
          />
        </div>
        <FadeUpSection classes="card-text m-auto max-[1000px]:w-full max-[1000px]:text-center w-1/2 p-20 max-[1000px]:px-10">
          <img className="w-[200px] mb-5" src={panerai} alt="Logo of Brand" />
          <p className="mb-0 sans">
            Luxury Italian watchmaker, Panerai invites you to a High
            Complications Showcase with one of their master watchmaking
            specialists. This expert will be on hand to answer guest questions
            and host live demonstrations of a rarely gathered collection of
            unique pieces. Immerse yourself into the world of Italy, adventure,
            and inspired design.
          </p>
          <div className="flex  max-[1000px]:m-auto">
            <TabComponent store={"Panerai"} color="white" />
          </div>
          <Nav.Link as={NavLink} to={"/home"}>
            <button className="bg-transparent border-solid border-theblue text-theblue px-4 py-1.5 mt-6">
              Book Now
            </button>
          </Nav.Link>
        </FadeUpSection>
      </div>
      <div className="flex font-playfair  flex-wrap text-left p-0 m-0 items-stretch  text-white bg-theblue">
        <FadeUpSection classes="card-text m-auto max-[1000px]:w-full max-[1000px]:text-center w-1/2 max-[1000px]:px-10 p-20">
          <img
            className="w-[160px] mb-5"
            src={piaget}
            loading="lazy"
            alt="Logo of Brand"
          />
          <p className="mb-0 sans">
            Piaget is pleased to invite you to experience immersive artisan
            workshops. Celebrating 150 years, mastery ignites artistry at
            Piaget, creating only the most unexpected and extraordinary watches
            and jewelry. Be the first to see exclusive pieces while you try your
            hand as an artisan with the Maison’s signature Décor Palace motif.
            You can also discover the Maison’s mastery of ultra-thin watchmaking
            through assembling one of its iconic Movements.
          </p>
          <div className="flex  max-[1000px]:m-auto">
            <TabComponent store={"Piaget"} color="blue" />
          </div>
          <Nav.Link as={NavLink} to={"/home"}>
            <button className="bg-transparent border-solid border-2 hover:border-10  border-white text-white px-4 py-1.5 mt-6">
              Book Now
            </button>
          </Nav.Link>
        </FadeUpSection>
        <div className="w-1/2 max-[1000px]:w-full ">
          <img
            className="w-full h-full  object-cover"
            src={piagetwatch}
            alt="Watch"
          />
        </div>
      </div>
      <div className="flex font-playfair flex-wrap-reverse m-a items-stretch text-left p-0 m-auto text-theblue bg-white">
        <div className="w-1/2 max-[1000px]:w-full">
          <img
            className="w-full h-full  object-cover "
            src={vacheronwatch}
            alt="Watch"
            loading="lazy"
          />
        </div>
        <FadeUpSection classes="card-text w-1/2 max-[1000px]:w-full max-[1000px]:text-center max-[1000px]:px-10 p-20 m-auto">
          <img
            className="w-3/4 max-w-[250px] mb-5"
            src={whitevacheronconstantin}
            alt="Logo of Brand"
          />
          <p className="mb-0 sans">
            Embark on a captivating journey into the realm of fine craftsmanship
            at the Vacheron Constantin Rodeo Drive Boutique. Discover the
            Maison’s Métiers d’Art craft of enamel painting and try your hand at
            this ancient technique as you create your own enameled artwork to
            take away.
          </p>
          <p className="mb-0 sans">
            Vacheron Constantin also presents "From Geometry to Artistry", an
            exhibition illustrating the subtle alchemy that transforms
            mechanical watchmaking into objets d'art. Selected from among some
            1,600 timepieces in the Maison’s heritage collection, an array of
            rare and exceptional watches will be exhibited during this time.
          </p>
          <p className="mb-0 sans">
            A unique experience where sophistication converges with horological
            excellence, ensuring an extraordinary and educational visit.
          </p>
          <p className="mb-0 sans">
            Book your experience at their Rodeo Drive boutique.
          </p>
          <div className="flex  max-[1000px]:m-auto">
            <TabComponent store={"Vacheron"} color="white" />
          </div>
          <Nav.Link as={NavLink} to={"/home"}>
            <button className="bg-transparent border-solid border-theblue text-theblue px-4 py-1.5 mt-6">
              Book Now
            </button>
          </Nav.Link>
        </FadeUpSection>
      </div>
    </div>
  );
}
export default Maisons;
