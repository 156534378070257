const PhoneIcon = ({ fill, size = 16, classes }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 312 312"
      className={classes}
    >
      <path
        fill={fill}
        d="M128.856 78.624c-3.724-42.842-48.418-63.863-50.31-64.72a9.497 9.497 0 0 0-5.674-.761c-51.597 8.56-59.358 38.59-59.67 39.838a9.652 9.652 0 0 0 .195 5.265C74.939 249.19 202.839 284.583 244.88 296.224c3.237.897 5.909 1.619 7.937 2.282a9.649 9.649 0 0 0 7.039-.41c1.287-.585 31.668-14.898 39.098-61.581a9.731 9.731 0 0 0-.936-5.967c-.663-1.287-16.556-31.531-60.626-42.217a9.526 9.526 0 0 0-8.619 2.047c-13.903 11.876-33.111 24.531-41.398 25.838-55.556-27.164-86.58-79.287-87.75-89.174-.683-5.557 12.051-25.077 26.695-40.95a9.727 9.727 0 0 0 2.535-7.468Z"
      />
    </svg>
  );
};

export default PhoneIcon;
