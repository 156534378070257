import React from 'react';

function EmailIcon({ size = 16, fill, classes }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 384 384"
      className={classes}
    >
      <g clipPath="url(#a)">
        <path
          fill={fill}
          d="M320 64H64c-17.6 0-31.84 14.4-31.84 32L32 288c0 17.6 14.4 32 32 32h256c17.6 0 32-14.4 32-32V96c0-17.6-14.4-32-32-32Zm-6.4 68-113.12 70.72c-5.12 3.2-11.84 3.2-16.96 0L70.4 132c-4-2.56-6.4-6.88-6.4-11.52 0-10.72 11.68-17.12 20.8-11.52L192 176l107.2-67.04c9.12-5.6 20.8.8 20.8 11.52 0 4.64-2.4 8.96-6.4 11.52Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h384v384H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EmailIcon;
