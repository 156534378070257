import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Register from './pages/register';
import Login from './pages/login';
import Home from './pages/home';
import ScrollToTop from './components/scrollToTop';
import Internal from './pages/internal';
import AdminPortal from './pages/adminportal';
import DigitalPassport from './pages/digitalpassport';
import VerifySend from './pages/verifySend';
import VerifyBlock from './pages/verifyBlock';
import PrivateRoutes from './components/privateRoute';
import VerifiedRoutes from './components/verifiedRoute';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import EventIntake from './pages/eventIntake';
import fetchData from './lib/fetchData';
import PrivacyPolicy from './pages/privacypolicy';
import Terms from './pages/terms';
import PromotionTerms from "./pages/promotion-terms";


function App() {
  const isAuth = JSON.parse(sessionStorage.getItem('auth'));
  const isVerified = JSON.parse(
    sessionStorage.getItem('email_verified')
  );
  const isAdmin = JSON.parse(sessionStorage.getItem('is_admin'));

  const [loggedIn, setLoggedIn] = useState(isAuth);
  const [verified, setVerified] = useState(isVerified);
  const [admin, setAdmin] = useState(isAdmin);

  const getAuth = async () => {
    try {
      const result = await fetchData('/check-authentication');
      if (result.data.auth) {
        setLoggedIn(true);
        sessionStorage.setItem('auth', true);
      } else {
        setLoggedIn(false);
        sessionStorage.setItem('auth', false);
      }

      if (result.data.verified) {
        setVerified(true);
        sessionStorage.setItem('email_verified', true);
      } else {
        setVerified(false);
        sessionStorage.setItem('email_verified', false);
      }

      if (result.data.role === 'admin') {
        setAdmin(true);
        sessionStorage.setItem('is_admin', true);
      } else {
        setAdmin(false);
        sessionStorage.setItem('is_admin', false);
      }
    } catch (err) {
      setLoggedIn(false);
      sessionStorage.setItem('auth', false);
      setVerified(false);
      sessionStorage.setItem('email_verified', false);
      setAdmin(false);
      sessionStorage.setItem('is_admin', false);
    }
  };

  useEffect(() => {
    getAuth();
  }, []);

  return (
    <div className="bg-white">
      <div className="App flex min-h-[100vh] max-w-[2500px] justify-between flex-col m-auto">
        <ScrollToTop />

        <Routes>
          <Route element={<PrivateRoutes isAuth={loggedIn} />}>
            <Route
              path="/please-verify"
              element={
                <VerifyBlock loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
              }
            />
            <Route element={<VerifiedRoutes isVerified={verified} />}>
              <Route
                path="/digitalpassport"
                element={
                  <DigitalPassport
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    isAdmin={admin}
                  />
                }
              />
              <Route
                path="/home"
                element={
                  <Internal
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    isAdmin={admin}
                  />
                }
              />
              <Route
                path="/admin"
                element={
                  <AdminPortal
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    isAdmin={admin}
                  />
                }
              />
            </Route>
          </Route>
          <Route
            path="/"
            element={
              <Home
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                isAdmin={admin}
              />
            }
          />

          <Route
            path="/register"
            element={<Register setLoggedIn={setLoggedIn} loggedIn={loggedIn} />}
          />
          <Route
            path="/login"
            element={
              <Login
                setLoggedIn={setLoggedIn}
                loggedIn={loggedIn}
                setVerified={setVerified}
                setAdmin={setAdmin}
              />
            }
          />
          <Route
            path="/forgot-password"
            element={<ForgotPassword setLoggedIn={setLoggedIn} />}
          />
          <Route
            path="/reset-password"
            element={
              <ResetPassword
                setVerified={setVerified}
                setLoggedIn={setLoggedIn}
                loggedIn={loggedIn}
                isAdmin={isAdmin}
              />
            }
          />
          <Route
            path="/check-verification"
            element={
              <VerifySend setLoggedIn={setLoggedIn} setVerified={setVerified} />
            }
          />
          <Route
            path="/event-registration"
            element={<EventIntake setLoggedIn={setLoggedIn} />}
          />
          <Route
            path="/privacy-policy"
            element={
              <PrivacyPolicy
                setLoggedIn={setLoggedIn}
                loggedIn={loggedIn}
                isAdmin={admin}
              />
            }
          />
          <Route
            path="/terms"
            element={
              <Terms
                setLoggedIn={setLoggedIn}
                loggedIn={loggedIn}
                isAdmin={admin}
              />
            }
          />
          <Route
            path="/promotion-terms"
            element={
              <PromotionTerms
                setLoggedIn={setLoggedIn}
                loggedIn={loggedIn}
                isAdmin={admin}
              />
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
