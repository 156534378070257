import AdminExportButton from '../components/AdminExportButton';
import Footer from '../components/footer';
import Navbar from '../components/navbar';

function AdminPortal({ loggedIn, setLoggedIn, isAdmin }) {
  return (
    <div className="login font-playfair bg-theblue text-white p-0 m-0">
      <Navbar
        loggedIn={loggedIn}
        setLoggedIn={setLoggedIn}
        isAdmin={isAdmin}
        color="white"
      />
      <AdminExportButton />
      <Footer color="blue" />
    </div>
  );
}
export default AdminPortal;
