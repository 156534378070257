import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import passportImg from '../assets/images/passport-img.png';
import homeAccent from '../assets/images/home-accent.png';
import React, { useState, useEffect } from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import SelectionModal from '../components/selection';
import PassportModal from '../components/passport';
import IWCModal from "../components/iwcmodal";

import BookingModal from '../components/booking';
import LoadSpinner from '../assets/svgs/loadSpinner';
import fetchUser from '../lib/fetchUser';
import map from '../assets/images/map.png';
import logo from '../assets/images/banner-logo.png';
import mobileMap from '../assets/images/mobile-map.jpg';

function Internal({ loggedIn, setLoggedIn, isAdmin }) {
  const [showModal, setShowModal] = useState(false);
  const [showPassport, setShowPassport] = useState(false);
  const [showBooking, setShowBooking] = useState(false);
  const [showIWC, setShowIWC] = useState(false);

  const [dataFromChild, setDataFromChild] = useState('');

  const [initialLoad, setInitialLoad] = useState(true);
  const [userName, setUserName] = useState('');
  const [error, setError] = useState('');

  const handleDataChange = (newData) => {
    setDataFromChild(newData);
  };

  const toggleModal = () => {
    setShowModal(true);
    setShowBooking(false);
  };

  const hideModal = () => {
    setShowModal(false);
    setShowBooking(false);
  };
    const showIWCModal = () => {
      setShowIWC(true);
      setShowModal(false);
    };

  const showBook = () => {
      setShowModal(false);
      setShowBooking(true);
  };

  const getUserName = async () => {
    const user = await fetchUser();
    if (!user.error) {
      setUserName(user.data.fname);
    } else {
      setError(
        user.error || 'Something went wrong, please try again.'
      );
    }
    setInitialLoad(false);
  };

  useEffect(() => {
    getUserName();
  }, []);

  const hideBook = () => {
    setShowBooking(false);
    setShowIWC(false);
    setShowModal(true);
  };
  const hideIWC = () => {
    setShowIWC(false);
    setShowModal(true);
  };
  useEffect(() => {
    const body = document.querySelector('body');

    if (showModal || showBooking || showPassport || showIWC) {
      body.classList.add('overflow-hidden');
    } else {
      body.classList.remove('overflow-hidden');
    }
    return () => {};
  }, [showModal, showBooking, showPassport, showIWC]);

  return (
    <div className="home font-playfair h-full w-full relative bg-theblue text-white p-0 m-0">
      <SelectionModal
        show={showModal}
        onClose={hideModal}
        onOpen={showBook}
        onIWCOpen={showIWCModal}
        onDataChange={handleDataChange}
      />
      <IWCModal
        show={showIWC}
        onClose={hideIWC}
        onOpen={showBook}
        onDataChange={handleDataChange}
      />
      <BookingModal
        className=""
        iwc={showIWC}
        brand={dataFromChild}
        show={showBooking}
        onClose={hideBook}
      />

      {showPassport && <PassportModal onClose={() => setShowPassport(false)} />}

      <Navbar
        color="white"
        className=" z-0"
        loggedIn={loggedIn}
        setLoggedIn={setLoggedIn}
        isAdmin={isAdmin}
      />
      {initialLoad ? (
        <div className="min-h-screen bg-white">
          <div className="mt-24">
            <LoadSpinner size="w-12 h-12" />
          </div>
        </div>
      ) : (
        <>
          <div className="flex-col flex lg:flex-row bg-white p-0 w-full min-h-[545px]">
            <div className="bg-theblue w-full min-h-[300px] lg:min-h-auto lg:w-[60%] p-16 relative overflow-hidden">
              <img
                src={homeAccent}
                className="absolute h-full w-auto top-0 right-0 z-0 opacity-10"
                alt=""
              ></img>
              <h1 className="mt-20 z-5 relative">
                <span className="italic text-[28px] sm:text-[48px]">
                  Welcome{userName && ","}
                </span>{" "}
                {/* <span className="text-[40px] sm:text-[50px] break-words">
                  <span></span>Welcome to Experience Time Americas!
                </span>{' '} */}
                <span className="text-[50px] sm:text-[70px] break-words">
                  {userName && userName}!
                </span>
              </h1>
              <button
                className="bg-[#916E3A] text-white text-[22px] outline-none border-none px-12 py-3 relative z-5 mt-16 mb-12 transition-transform book-btn"
                onClick={toggleModal}
              >
                Book a Session
              </button>
            </div>
            <div className="bg-[#93B5CD] flex-1 p-16">
              <button
                className="outline-none bg-transparent border-none transition transform ease-in passport-btn"
                onClick={() => setShowPassport(true)}
              >
                <img
                  src={passportImg}
                  alt="digital passport"
                  className="w-[220px] sm:w-[300px]"
                />
              </button>
            </div>
          </div>
          <div className="bg-white px-16 py-36 text-center">
            <div className="max-w-[700px] mx-auto">
              <h2 className="text-theblue font-playfair mb-8 text-4xl">
                Complete your digital passport and enter a drawing for an
                exclusive prize.
              </h2>
              <p className="text-black sans">
                While visiting each of the five participating maisons, receive
                one stamp per boutique by scanning the in-store QR code to
                complete your digital passport. Once complete, you will be
                entered into a drawing to win an exclusive prize.
              </p>
              <p className="text-black sans">
                No purchase necessary. Open only to residents of the 50 United
                States and D.C. that are 18 years of age or older. Void where
                prohibited. For Official Rules, including entry instructions and
                AMOE option, please visit{" "}
                <a
                  href="promotion-terms"
                  className="bg-transparent border-none outline-none text-inherit underline"
                  rel="noreferrer"
                >
                  here{""}
                </a>
                .
              </p>
              <button
                className="bg-theblue text-white text-[22px] outline-none border-none px-12 py-3 relative z-5 mt-16 mb-12 transition-transform book-btn"
                onClick={() => setShowPassport(true)}
              >
                View Passport
              </button>
            </div>
          </div>
          <div className="bg-theblue p-0 flex justify-between">
            <div className="md:w-full hidden w-0 md:flex items-center justify-center">
              <img src={logo} className="min-w-[300px] w-1/2" alt="logo"></img>
            </div>
            <img
              src={map}
              alt="map of the boutiques"
              className="hidden md:block md:w-1/2"
            />
            <img
              src={mobileMap}
              alt="map of the boutiques"
              className="block md:hidden w-full"
            />
          </div>
        </>
      )}

      <Footer color="white" />
    </div>
  );
}

export default Internal;
