const validateRegistration = (values) => {
  const validationErrors = {};

  if (!values.firstname)
    validationErrors.firstname = 'First name is required';
  if (!values.lastname)
    validationErrors.lastname = 'Last name is required';
  if (!values.email || !/\S+@\S+\.\S+/.test(values.email))
    validationErrors.email = 'Invalid email address';
  if (
    values.password.length < 10 ||
    !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$ %^&*-])/.test(
      values.password
    )
  )
    validationErrors.password =
      'Password must contain at least one number, one uppercase and lowercase letter, one symbol, and be at least 10 characters long';
  if (values.password !== values.confirmpassword)
    validationErrors.confirmpassword = 'Passwords do not match';
  if (!values.country)
    validationErrors.country = 'Country of residence is required';

  return validationErrors;
};

const validateWatchtime = (values) => {
  const validationErrors = {};

  if (!values.firstName)
    validationErrors.firstName = 'First name is required';
  if (!values.lastName)
    validationErrors.lastName = 'Last name is required';
  if (!values.email || !/\S+@\S+\.\S+/.test(values.email))
    validationErrors.email = 'Invalid email address';

  return validationErrors;
};

export { validateRegistration, validateWatchtime };
