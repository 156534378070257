import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LoadSpinner from '../assets/svgs/loadSpinner';
import Navbar from '../components/navbar';
import { useNavigate } from 'react-router-dom';
import useEffectOnce from '../hooks/useEffectOnce';
import fetchWithCSRF from '../lib/fetchWithCSRF';
import Footer from '../components/footer';
import useCaptcha from '../hooks/useCaptcha';

function VerifySend({ setLoggedIn, setVerified }) {
  let [searchParams] = useSearchParams();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendError, setResendError] = useState('');
  const [success, setSuccess] = useState(false);
  const [sent, setSent] = useState(false);

  const navigate = useNavigate();
  const token = searchParams.get('token');
  const id = searchParams.get('id');

  const redirectValue = searchParams.get('redirect');
  const executeRecaptcha = useCaptcha();

  const resendCode = async () => {
    setResendLoading(true);
    setSuccess(false);
    setResendError('');
    try {
      const recaptchaToken = await executeRecaptcha();
      const result = await fetchWithCSRF(
        `/api/resend-email`,
        {
          redirectValue: redirectValue,
          id: id,
          token: recaptchaToken,
        },
        'POST'
      );
      const jsonResponse = await result.json();
      if (!jsonResponse.success) {
        if (jsonResponse.error) {
          setResendError(jsonResponse.error);
        } else {
          setResendError('Server Error');
        }
      } else {
        setSuccess(true);
        setSent(true);
      }
    } catch (err) {
      setResendError('Server Error');
    } finally {
      setResendLoading(false);
    }
  };

  const verifyEmail = async () => {
    try {
      fetchWithCSRF(
        `/api/verify-email`,
        {
          token: token,
          id: id,
        },
        'POST'
      )
        .then(async (result) => {
          const data = await result.json();
          if (data.success) {
            if (data?.auth && data?.['email_verified']) {
              setLoggedIn(true);
              setVerified(true);
              sessionStorage.setItem('auth', true);
              sessionStorage.setItem('email_verified', true);

              navigate(redirectValue ? '/' + redirectValue : '/home');
            }
          } else {
            if (data.error === 'Email already verified') {
              navigate('/login');
            } else {
              setError(
                data.error || 'Something went wrong, please try again'
              );
            }
          }
        })
        .catch((err) => {
          setError('Something went wrong, please try again');
        });
    } catch (err) {
      setError('Email address could not be verified');
    } finally {
      setLoading(false);
    }
  };

  useEffectOnce(() => {
    verifyEmail();
  }, [token]);

  return (
    <div className="login font-playfair bg-theblue text-white p-0 m-0">
      <Navbar color="white" setLoggedIn={setLoggedIn} />
      <div className="py-10 w-full min-h-[73vh]  m-auto text-left bg-white">
        {loading && <LoadSpinner />}
        {error && (
          <div className="mx-auto text-center max-w-[350px]">
            <p className="text-theblue">{error}</p>
            <button
              onClick={resendCode}
              className="bg-theblue border-0 text-white py-2 px-4 disabled:opacity-60 min-w-[350px] mx-auto sans text-sm"
              disabled={resendLoading || sent}
            >
              {resendLoading ? (
                <LoadSpinner />
              ) : (
                'Resend verification code'
              )}
            </button>
            {resendError && (
              <div
                className={`mt-2 h-auto ${
                  resendError && 'bg-[#ef444416]'
                }`}
              >
                <p className="text-red-600 sans  text-sm text-center py-2 mt-4">
                  {resendError}
                </p>
              </div>
            )}
            {success && (
              <div className="mt-2 min-h-8 h-auto p-2 bg-blue-50">
                <p className="text-blue-900 text-sm text-center mt-2 mb-1 sans">
                  Success! We've sent a password reset link to your
                  email address. Please check your inbox and follow
                  the instructions to reset your password.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer color="blue" />
    </div>
  );
}

export default VerifySend;
