import fetchData from './fetchData';

const fetchWithCSRF = async (endpoint, payload, method) => {
  let result = null;

  const getCSRFToken = async () => {
    const { data } = await fetchData('/api/csrf-token', {
      withCredentials: true,
    });
    return data.csrfToken;
  };

  try {
    const csrfToken = await getCSRFToken();

    const res = await fetch(endpoint, {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'xsrf-token': csrfToken,
      },
      credentials: 'include',
      mode: 'cors',
      body: payload ? JSON.stringify(payload) : null,
    });
    result = res;
  } catch (err) {
    result = err;
  }
  return result;
};

export default fetchWithCSRF;
