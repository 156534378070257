import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

function PrivacyPolicy({ setLoggedIn, loggedIn, isAdmin }) {
  return (
    <div className="home font-playfair bg-theblue text-white p-0 m-0">
      <Navbar
        color="white"
        setLoggedIn={setLoggedIn}
        loggedIn={loggedIn}
        isAdmin={isAdmin}
      />
      <div className="bg-white">
        <h1 className="text-theblue">Privacy Statement</h1>
        <div className="sans text-black my-12 max-w-[700px] mx-auto text-left">
          <h2 className="uppercase text-lg">
            ABOUT EXPERIENCE TIME - THE RODEO DRIVE EDITION AND THIS
            PRIVACY STATEMENT
          </h2>
          <p>
            The ‘The Experience Time – The Rodeo Drive Edition’ is an
            opportunity to enjoy the precision of beautiful
            timepieces, learn more about next generation watchmaking
            materials, meet our artisans, forget about time, live in
            the moment and dream (the “Event”). The website found at
            https://www.experiencetimeamericas.com (“Website”) and the
            Event is run on behalf of the following Maisons:
          </p>
          <ul>
            <li className="mb-1">IWC Schaffhausen</li>
            <li className="mb-1">Jaeger-LeCoultre</li>
            <li className="mb-1">Officine Panerai</li>
            <li className="mb-1">Piaget</li>
            <li className="mb-1">Vacheron Constantin</li>
          </ul>
          <p>
            Any personal information which you provide through the
            Website is collected, used and disclosed in accordance
            with each individual Maison’s privacy policy. For more
            details on how each Maison handles your information please
            refer to the section titled ‘Maison Privacy Policies’
            below.
          </p>
          <br />
          <br />

          <h2 className="uppercase text-lg">
            USE OF COOKIES ON THE WEBSITE
          </h2>
          <p>
            Like most websites, the Website logs IP addresses and uses
            cookies and similar technologies that allow you to be
            recognised and to customise your experience, as well as to
            provide the Maisons with information about the way
            visitors access the Website.
          </p>
          <br />
          <br />

          <h2 className="uppercase text-lg">WHAT IS A COOKIE?</h2>
          <p>
            Cookies are text files, containing small amounts of
            information, which are downloaded to your computer or
            mobile device when you visit a website or mobile
            application. They are used to recognise your computer as
            you move between pages on a website, or when you return to
            a website or mobile application you have visited
            previously. Cookies are widely used in order to make
            platforms work, or to work more efficiently, as well as to
            provide information to the owners of the platform.
          </p>
          <p>
            Cookies are used on the Website to enhance your online
            experience (for example, by remembering your language and
            other preferences) and to better understand how the
            Website is used, for example, whether you have visited the
            Website before or whether you are a new visitor. There are
            two broad categories of cookies:
          </p>
          <ul>
            <li>
              First party cookies, served directly to your computer or
              mobile device. They are used to recognise your computer
              or mobile device when it revisits the Website.
            </li>
            <li>
              Third party cookies, which are served by a third party
              service provider on the Website, and can be used by the
              service provider to recognise your computer or mobile
              device when it visits other platforms. Third party
              cookies are most commonly used for platform analytics or
              advertising purposes.
            </li>
          </ul>
          <p>
            Cookies can remain on your computer or mobile device for
            different periods of time. Both 'session cookies' and
            ‘permanent cookies’ are used on the Website. Session
            cookies exist only while your browser is open and are
            deleted automatically once you close your browser.
            Permanent cookies survive after your browser is closed,
            and can be used to recognise your computer or mobile
            device when you open your browser and browse the internet
            again.
          </p>
          <br />
          <br />

          <h2 className="uppercase text-lg">
            WHAT COOKIES DOES THE WEBSITE USE?
          </h2>
          <p>
            The Website serves only the following types of cookies to
            your computer or mobile device:
          </p>
          <p>
            <strong>
              Cookies necessary for essential purposes:{' '}
            </strong>
            These cookies are essential to provide you with services
            available through the Website and to use some of its
            features, such as access to secure areas. Without these
            cookies, services you have asked for, like secure login
            accounts, would not be possible.
          </p>
          <br />
          <p>
            <strong>Functional Cookies: </strong>
            These cookies serve a variety of purposes related to the
            presentation, performance and functionality of the
            Website. Their overall purpose, however, is to enhance
            visitors’ experience and enjoyment of the Website. For
            example, some of these cookies enable visitors to specify
            language, product or other Website preferences.
          </p>
          <br />
          <p>
            <strong>Performance Cookies: </strong>
            These cookies are used to collect information about how
            visitors use the Website. The information gathered does
            not identify any individual visitor and is aggregated. It
            includes the number of visitors to the Website and the
            pages that were visited on the Website. This information
            is used to help run the Website more efficiently, to
            gather broad demographic information and to monitor the
            level of activity on the Website.
          </p>
          <br />
          <br />

          <h2 className="uppercase text-lg">
            HOW TO CONTROL OR DELETE COOKIES?
          </h2>
          <p>
            Non-essential cookies are used on the Website on the basis
            of your consent. You have the right withdraw that consent
            and refuse the use of cookies at any time and you can
            exercise this right by following the steps set out below.
            However, please note that if you choose to refuse cookies
            you may not be able to use the full functionality of the
            Website.
          </p>
          <p>
            You can set your cookie preferences by changing your
            browser settings so that cookies from the Website cannot
            be placed on your computer or mobile device. In order to
            do this, follow the instructions provided by your browser
            (usually located within the “Help”, “Tools” or “Edit”
            facility).
          </p>
          <p>
            Further information about cookies, including how to see
            what cookies have been set on your computer or mobile
            device and how to manage and delete them, visit{' '}
            <a
              href="www.allaboutcookies.org"
              target="_blank"
              rel="noreferrer"
            >
              www.allaboutcookies.org
            </a>{' '}
            and{' '}
            <a
              href="www.youronlinechoices.eu"
              target="_blank"
              rel="noreferrer"
            >
              www.youronlinechoices.eu
            </a>
            .
          </p>
          <br />
          <br />

          <h2 className="uppercase text-lg">IP ADDRESSES</h2>
          <p>
            Information about your computer or mobile device will be
            collected, including where available your IP address,
            operating system, log-in times and browser type. This
            information is used to better understand how visitors use
            the Website and for internal reporting purposes. This
            information is anonymised and will be shared with
            advertisers, sponsors or other businesses.
          </p>
          <br />
          <br />

          <h2 className="uppercase text-lg">
            MAISON PRIVACY POLICIES
          </h2>
          <p>
            If you have any questions or comments about this privacy
            statement, or privacy matters generally, please contact
            the relevant Maison using the contact details provided in
            its privacy policy, provided below. You can also use the
            Maison contact details if you wish to request access to
            the personal information each Maison holds about you or to
            unsubscribe from any further e-mail marketing
            communications.
          </p>
          <ul>
            <li className="mb-1">
              <a
                href="https://www.iwc.com/us/en/terms-and-legal/privacy-policy.html"
                target="_blank"
                rel="noreferrer"
              >
                IWC Schaffhausen
              </a>
            </li>
            <li className="mb-1">
              <a
                href="https://www.jaeger-lecoultre.com/us-en/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Jaeger-LeCoultre
              </a>
            </li>
            <li className="mb-1">
              <a
                href="https://www.panerai.com/us/en/quick-links/privacy-policy.html"
                target="_blank"
                rel="noreferrer"
              >
                Officine Panerai
              </a>
            </li>
            <li className="mb-1">
              <a
                href="https://www.piaget.com/us-en/legal/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Piaget
              </a>
            </li>
            <li className="mb-1">
              <a
                href="https://www.vacheron-constantin.com/us/en/legal/privacy-policy.html"
                target="_blank"
                rel="noreferrer"
              >
                Vacheron Constantin
              </a>
            </li>
          </ul>
          <br />
          <p>Last Updated: September 2024</p>
        </div>
      </div>
      <Footer color="blue" />
    </div>
  );
}
export default PrivacyPolicy;
