import { useState, useEffect } from 'react';
const useCaptcha = () => {
  const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    if (window.grecaptcha && window.grecaptcha.enterprise) {
      setIsReady(true);
    } else {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${sitekey}`;
      script.async = true;
      document.head.appendChild(script);
      script.onload = () => {
        setIsReady(true);
      };
    }
  }, [sitekey]);
  const executeRecaptcha = async () => {
    if (
      isReady &&
      window.grecaptcha &&
      window.grecaptcha.enterprise
    ) {
      const key = await window.grecaptcha.enterprise.execute(
        sitekey,
        {
          action: 'submit', // Adjust action based on your needs, e.g., 'LOGIN'
        }
      );
      return key;
    }
  };
  return executeRecaptcha;
};
export default useCaptcha;
