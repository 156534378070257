import React from 'react';
import Navbar from '../components/navbar';
import { useState } from 'react';
import LoadSpinner from '../assets/svgs/loadSpinner';
import { useSearchParams } from 'react-router-dom';
import fetchWithCSRF from '../lib/fetchWithCSRF';
import useCaptcha from '../hooks/useCaptcha';

function VerifyBlock({ loggedIn, setLoggedIn }) {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();

  const redirectValue = searchParams.get('redirect');

  const executeRecaptcha = useCaptcha();

  const resendCode = async () => {
    setLoading(true);
    setError('');
    const recaptchaToken = await executeRecaptcha();
    try {
      const result = await fetchWithCSRF(
        `/api/resend-email`,
        {
          redirectValue: redirectValue,
          token: recaptchaToken,
        },
        'POST'
      );
      const jsonResponse = await result.json();
      if (!result.ok) {
        setError('Hmm...something went wrong');
      } else if (!jsonResponse.success && jsonResponse.error) {
        setError(jsonResponse.error || 'Hmm...something went wrong');
      } else {
        setSent(true);
      }
    } catch (err) {
      setError('Hmm...something went wrong');
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="bg-white">
      <Navbar
        setLoggedIn={setLoggedIn}
        loggedIn={loggedIn}
        showPassportLink={false}
        color="white"
      />
      <div className="font-playfair text-white p-0 mt-[150px] h-[calc(100vh-150px)] max-w-[700px] mx-auto">
        <h1 className="text-2xl text-theblue font-semibold mb-8">
          Please verify your email address
        </h1>
        <p className="text-theblue sans">
          To activate your account, please check your email inbox for
          a verification link.
        </p>
        <button
          className="bg-theblue border-0 text-white m-auto py-2 px-4 my-4 disabled:opacity-60 w-[300px] text-sm sans"
          onClick={resendCode}
          disabled={sent || loading}
        >
          {loading ? <LoadSpinner /> : 'Resend verification'}
        </button>
        {sent && (
          <div className="mt-2 min-h-8 h-auto p-2 bg-blue-50 max-w-[300px] w-max-content mx-auto">
            <p className="text-blue-900 text-sm text-center mt-2 mb-1 w-max-content sans">
              Success! Please click the link in your email to complete
              the verification.
            </p>
          </div>
        )}
        {error && (
          <div
            className={`mt-2 h-auto ${
              error && 'bg-[#ef444416]'
            } max-w-[350px] mx-auto`}
          >
            <p className="text-red-600 sans  text-sm text-center py-2 mt-4">
              {error}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default VerifyBlock;
