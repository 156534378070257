import React, { useRef, useEffect } from 'react';

export default function AutoPlaySilentVideo({ className, video }) {
  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });
  return (
    <video
      className={className}
      ref={videoRef}
      loop
      autoPlay
      muted
      playsInline
    >
      <source src={video} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}
